// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import {
  ICustomTableProps,
  IInputConfigProps,
  ITableActionBtnConfigProps,
} from "../../../components/src/CommonType.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  tenantCourseMngTabCategory: string;
  tableHeaderColumns: Array<ICustomTableProps>;
  tableBodyData: Array<any>;
  inputDDConfigs: Array<IInputConfigProps>;
  tableActionButtonConfig?: Array<ITableActionBtnConfigProps>;
  handleSearch?: any;
  handleSelectDropdownValue: any;
  renderDDMenuItem: any;
  showMenuIconInTable: boolean;
  showTablePagination?: boolean;
  addTablePadding?: boolean;
  maxHeightForTableBox?: string;
  pagination?: any;
  getPaginationNumber?: any;
  tableRowClickAction?: any;
  rowWiseTableActionConfig?: any;
  showRowWiseActionMenu?: boolean;
  requestIcons?:boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  catalogSearchInput:string;
  searchFlag:boolean;
  filteredTableData:Array<any>
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TenantCourseManagementMainTabComponentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      catalogSearchInput:'',
      searchFlag:false,
      filteredTableData:[]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    this.setState({ searchFlag: true });
    await super.componentDidMount();
    // Customizable Area Start
    this.courseManagementTabComponent();
    // Customizable Area End
  }

  // Customizable Area Start
  courseManagementTabComponent = () => {
    console.log("tab component");
  };
  handleSearchInput=(e:any)=>{
    this.setState({catalogSearchInput:e?.target.value})
  }
  handleSearchButton = () => {
    this.props.handleSearch();
  };

  handlePagination = (event: any, page: number) => {
    this.props.getPaginationNumber(page);
  }
  // Customizable Area End
}
// Customizable Area End
