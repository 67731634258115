import { BlockComponent } from '../../../framework/src/BlockComponent';
import { runEngine } from '../../../framework/src/RunEngine';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { getStorageData } from '../../../framework/src/Utilities';
import {
  displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from '../../../components/src/CommonHelper.web';
import moment from 'moment';

const configJSON = require('./config.js');

export interface Props {
  navigation?: unknown;
  classes: unknown;
  isOpen: boolean;
  handleClose: () => void;
  onAssignToTeacherClick: () => void;
  catalog: {
    name: string;
    id: number;
  };
  isCatalogWrite:boolean
}

type TableData = {
  id: number;
  teacher: string;
  assignedOn: string;
}[];
interface S {
  shouldRenderConfirmDialog: boolean;
  shouldRenderSuccessDialog: boolean;
  tableData: TableData;
  selectedId: null | number;
}

interface SS {
  id: unknown;
}

export default class AssignedDetailsModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  removeTeacherFromCatalogRequest = '';
  getAssignedTeachersRequest = '';

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      shouldRenderConfirmDialog: false,
      shouldRenderSuccessDialog: false,
      selectedId: null,
      tableData: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();

    this.initialize();
  }

  initialize = () => {
    const isInCatalog = this.props.catalog.id;
    if (isInCatalog) this.getAssignedTeachers(this.props.catalog.id);
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    const isCatalogChanged = this.props.catalog.id !== prevProps.catalog.id;

    if (isCatalogChanged) this.getAssignedTeachers(this.props.catalog.id);

    const isVisibilityChanged = this.props.isOpen !== prevProps.isOpen;

    if (isVisibilityChanged) this.initialize();
  }

  receive = (from: string, message: Message) => {
    const { apiRequestCallId, errorResponse, responseJson } =
      getMessageData(message);

    if (apiRequestCallId === this.getAssignedTeachersRequest)
      this.handleGetAssignedTeachersResponse(responseJson, errorResponse);

    if (apiRequestCallId === this.removeTeacherFromCatalogRequest)
      this.handleRemoveTeacherFromCatalogResponse(responseJson, errorResponse);
  };

  /**
   * Sets the statefor the selected row
   * @param id - the row id
   */
  selectRow = (id: number) => {
    this.setState({ selectedId: id });
  };

  /**
   * Retrieves the selected row based on selected id
   * @returns the selected row
   */
  getSelectedRow = () => {
    const { selectedId, tableData } = this.state;
    const hasSelectedId = selectedId !== null;

    if (!hasSelectedId) throw Error('No row selected');

    const selectedRow = tableData.find(row => row.id === selectedId)!;

    return selectedRow;
  };

  /**
   * Runs when delete operation is confirmed
   */
  onDeleteConfirm = () => {
    this.hideConfirmDialog();
    this.removeTeacherFromCatalog();
  };

  /**
   * Runs when okay button is pressed on success dialog
   */
  onSuccessOkay = () => {
    this.getAssignedTeachers(this.props.catalog.id);
    this.hideSuccessDialog();
  };

  /**
   * Maps response to table data
   */
  handleGetAssignedTeachersResponse = async (
    responseJson: unknown,
    errorResponse: unknown
  ) => {
    const errorInResponse = (responseJson as { errors: string })?.errors;
    const noData = typeof (responseJson as { data: {} }).data === 'undefined';
    const hasError = errorResponse || errorInResponse || noData;

    if (hasError) {
      displayApiErrorMsg(errorInResponse, this.props.navigation);
      this.parseApiCatchErrorResponse(errorResponse);

      return;
    }

    const data = (responseJson as any).data?.attributes?.teacher_catalogues.data;
    const nextTableData: TableData = data?.map((datum: unknown) => {
      const data = datum as any;
      const teacher = data?.attributes?.teacher;

      const id = teacher?.id;
      const fullName = `${teacher?.first_name} ${teacher?.last_name}`;
      const assignedOn = moment(
        data?.attributes?.created_at).format('MMM DD, yyyy');

      return { id, teacher: fullName, assignedOn };
    });

    this.setTableData(nextTableData);
  };

  /**
   * Send a GET request to get assigned teachers on the catalog. This runs on mount, on every catalog id change, and on success dialog confirm after remove teacher operation
   */
  getAssignedTeachers = async (catalogId: number) => {
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAssignedTeachersRequest = request.messageId;

    const endpoint = configJSON.postAddNewCatalog;
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endpoint}/${catalogId}`
    );

    const token = await getStorageData('token');
    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token,
    };
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const getMethod = configJSON.validationApiMethodType;
    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getMethod
    );

    runEngine.sendMessage(request.id, request);
  };

  /**
   * Hides loader and shows success dialog
   */
  handleRemoveTeacherFromCatalogResponse = (
    responseJson: unknown,
    errorResponse: unknown
  ) => {
    hideCustomLoader();

    this.showSuccessDialog();
  };

  /**
   * Sends a request to remove teacher from catalog
   */
  removeTeacherFromCatalog = async () => {
    showCustomLoader();

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.removeTeacherFromCatalogRequest = request.messageId;

    const endpoint = configJSON.assignCatalogToTeacher;
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    const token = await getStorageData('token');
    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token,
    };
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const postMethod = configJSON.exampleAPiMethod;
    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      postMethod
    );

    const selectedRow = this.getSelectedRow();
    const teacherId = selectedRow.id;
    const catalogId = this.props.catalog.id;
    const body = {
      catalogue_id: catalogId,
      removed_teacher_ids: [teacherId],
    };

    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(request.id, request);
  };

  /**
   * Sets the state for table data
   */
  setTableData = (tableData: TableData) => {
    this.setState({ tableData });
  };

  /**
   * Sets the state to show success dialog
   */
  showSuccessDialog = () => {
    this.setState({ shouldRenderSuccessDialog: true });
  };

  /**
   * Sets the state to hide success dialog
   */
  hideSuccessDialog = () => {
    this.setState({ shouldRenderSuccessDialog: false });
  };

  /**
   * Sets the state to show confirm dialog
   */
  showConfirmDialog = () => {
    this.setState({ shouldRenderConfirmDialog: true });
  };

  /**
   * Sets the state to hide confirm dialog
   */
  hideConfirmDialog = () => {
    this.setState({ shouldRenderConfirmDialog: false });
  };
}
