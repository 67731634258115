// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme, ThemeProvider, createTheme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography, Grid, Button, Radio, TextField, CircularProgress } from '@material-ui/core';
import AddNewActivityModalController, {
    Props
} from "./AddNewActivityModalController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../components/src/CustomButton.web';
import { combineStyles } from '../../../components/src/CombineStyles.web';
import CustomTextField from "../../../components/src/CustomTextField.web";
import SearchIcon from "../../../components/src/SVGIcons/SearchIcon.web";
import CustomTextEditor from '../../../components/src/CustomTextEditor.web';
import CustomCheckBoxComponent from "../../../components/src/CustomCheckBoxComponent.web";
import CheckboxCheckIcon from "../../../components/src/SVGIcons/CheckboxCheckIcon.web";
import CheckboxUnCheckIcon from "../../../components/src/SVGIcons/CheckboxUnCheckIcon.web";
import VideoBottomTextTopIcon from "../../../components/src/SVGIcons/VideoBottomTextTopIcon.web";
import VideoTopTextBottomIcon from '../../../components/src/SVGIcons/VideoTopTextBottomIcon.web';
import VideoRightTextLeftIcon from '../../../components/src/SVGIcons/VideoRightTextLeftIcon.web';
import VideoLeftTextRightIcon from '../../../components/src/SVGIcons/VideoLeftTextRightIcon.web';
import AudioBottomTextTopIcon from '../../../components/src/SVGIcons/AudioBottomTextTopIcon.web';
import AudioTopTextBottomIcon from '../../../components/src/SVGIcons/AudioTopTextBottomIcon.web';
import CustomSelectDropdown from '../../../components/src/CustomSelectDropdown.web';
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import Switch from '@material-ui/core/Switch';
import VideoPreviewDialog from './VideoPreviewDialog.web';
import AudioPreviewDialog from './AudioPreviewDialog.web';
import SearchExistingActivityModal from './SearchExistingActivityModal.web';
import CustomDatepicker from '../../../components/src/CustomDatepicker.web';
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        addNewTopicModal: {
            "& .MuiDialog-paperWidthSm": {
                width: "880px",
                maxWidth: "100%",
                overflowX: "hidden",
                minHeight: "500px",
                overflowY: "hidden",
            },
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            },
        },
        customInput: {
            height: "50px",
            borderRadius: "12px",
            width: "100%",
            padding: "17px 15px",
            fontSize: "14px",
            border: "1px solid #888",
            fontWeight: 400,
            marginTop: "10px",
            "&::after": {
                borderBottom: "0px"
            },
            "&::before": {
                borderBottom: "0px"
            },
            "&:hover:not(.Mui-)::before": {
                borderRadius: "12px",
                borderBottom: "1px solid #888",
            },
            "&.MuiInputBase-root.Mui-": {
                background: "#F3F3F3"
            }
        },
        customTextarea: {
            borderRadius: "12px",
            width: "96%",
            padding: "17px 15px",
            fontSize: "14px",
            border: "1px solid #888",
            fontWeight: 400,
            marginTop: "10px",
            "&::before": {
                borderBottom: "0px"
            },
            "&::after": {
                borderBottom: "0px"
            },
            "&:hover:not(.Mui-)::before": {
                borderRadius: "12px",
                borderBottom: "1px solid #888",
            },
            "&.MuiInputBase-root.Mui-": {
                background: "#F3F3F3"
            },
            "& .MuiInput-underline::before": {
                borderBottom: "0px",
                content: "none",
            },
            "& .MuiInput-underline::after": {
                borderBottom: "0px",
            }
        },
        headerRow: {
            display: "flex",
            justifyContent: "space-between",
            flexFlow: "row",
        },
        requiredField: {
            color: "red"
        },
        titleAndSearch: {
            flexFlow: "row",
            display: "flex",
            justifyContent: "start"
        },
        radioButtonLabel: {
            color: "#888",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "20px",
            marginRight: "15px",
        },
        checkedLabelColor: {
            fontWeight: 500,
            color: '#FFD92E',
        },
        radio: {
            height: "24px",
            width: "24px",
            '&$checked': {
                color: '#FFD92E'
            },
            "& .MuiSvgIcon-root": {
                fontSize: "30px !important"
            }
        },
        radioUnchecked: {
            width: "24px",
            strokeWidth: "1px",
            height: "24px",
            color: '#888',
            '&$checked': {
                color: '#888'
            },
            "& .MuiSvgIcon-root": {
                fontSize: "30px !important"
            }
        },
        checked: {},
        dividerGrid: {
            position: "relative",
            marginRight: "-30px",
            marginLeft: "-30px",
        },
        dividerLine: {
            height: "1px",
            margin: "10px -25px 15px -25px",
            background: "#F1F1F1",
        },
        browseTextField: {
            position: "relative",
            maxWidth: "100%",
            borderRadius: "12px",
            marginTop: "10px",
            width: "400px",
            border: "1px solid #888",
            height: "50px",
            "& .MuiInputBase-root": {
                height: "50px",
            },
            "& .MuiFormHelperText-contained": {
                marginLeft: "0px",
            },
        },
        fileInput: {
            display: 'none',
        },
        browseText: {
            width: "max-content",
            color: "#0D46BC",
            cursor: 'pointer',
            fontSize: 14,
            fontWeight: 500,
            display: "block",
        },
        activeShowCorrecAnswer: {
            color: "#009DFF",
            fontWeight: 500,
        },
        customLoader: {
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: -30,
            backgroundColor: "rgb(0, 0, 0, .4)",
            zIndex: 100,
            display: "block",
        },
        circularContainer: {
            position: "absolute",
            left: "50%",
            top: "40%",
            transform: "translate(-50%, -50%)",
        },
    })

const searchTheme = createTheme({
    overrides: {
        MuiInputBase: {
            input: {
                "&::placeholder": {
                    fontSize: "14px",
                },
            },
        },
        MuiSvgIcon: {
            root: {
                height: "16px",
                width: "16px",
            },
        },
        MuiFormControl: {
            root: {
                justifyContent: "center",
                height: "35px",
                width: "max-content",
                display: "flex",
                marginLeft: "24px",
                alignItems: "center",
            },
        },
    },
});

// Customizable Area End

// Customizable Area Start
export class AddNewActivityModal extends AddNewActivityModalController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }

    AntSwitch = withStyles((theme) => ({
        root: {
            width: 46,
            height: 26,
            borderRadius: "18.5px",
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: "#888",
            transform: 'translateX(20px)',
            '&$checked': {
                transform: 'translateX(1px)',
                color: "FFF",
                '& + $track': {
                    opacity: 1,
                    backgroundColor: "#009DFF",
                    borderColor: "#009DFF",
                },
            },
        },
        thumb: {
            width: 22,
            height: 22,
            boxShadow: 'none',
            background: "#FFF",
        },
        track: {
            border: `1px solid #888`,
            borderRadius: "18.5px",
            opacity: 1,
            backgroundColor: "#888",
        },
        checked: {},
    }))(Switch);
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open, isEdit } = this.props;
        const {
            selectedActivityType,
            selectXAPI,
            selectedVideoDescPlacement,
            selectedEvaluationType,
            selectedGradingComponent,
            gradingComponentData,
            selectedAllowedAttempts,
            allowedAttemptsData,
            showCorrectAnswers,
            openVideoPreviewDialog,
            openAudioPreviewDialog,
            selectedObjectiveType,
            dueDateInputType,
            openExistingActivityModal,
            lessonTitleValue,
            activityTitleValue,
            taskValue,
            descriptionValue,
            instructionValue,
            selectedAudioDescPlacement,
            selectedDurationValue,
            maxScoreValue,
            dueDateValue,
            activityTitleError,
            activityTitleMsg,
            taskError,
            taskErrorMsg,
            descriptionError,
            descriptionErrorMsg,
            mediaFileError,
            mediaFileErrorMsg,
            xampiFileError,
            xampiFileErrorMsg,
            instructionError,
            instructionErrorMsg,
            dueDateError,
            dueDateErrorMsg,
            maxScoreError,
            maxScoreErrorMsg,
            loading,
            activitySearchData,
            selectedAllowedAttemptsError,
            selectedAllowedAttemptsErrorMsg
        } = this.state;
        return (
            <>
                <Box>
                    <Dialog className={`${classes.highlightBgColor} ${classes.addNewTopicModal}`} aria-labelledby="quiz-view-answer-confirm-dialog-title" open={open} scroll={'body'}>
                        {this.handleCheckComponentRender(loading ,(
                            <Box className={classes.customLoader}>
                                <Box className={classes.circularContainer}>
                                    <CircularProgress className={`${classes.textButton}`} />
                                </Box>
                            </Box>
                        ))}
                        <Box padding={"30px"} className={`${classes.bgColor}`}>
                            <Grid direction="row" container>
                                <Grid xs={12} item className={classes.headerRow}>
                                    <Box className={classes.titleAndSearch}>
                                        <Typography className={`${classes.fontText24} ${classes.fontBold500} ${classes.textPrimaryColor}`} component={"span"}>{this.handleTertiaryCondition(isEdit , 'Edit Activity' , 'Add New Activity')}</Typography>
                                        <ThemeProvider theme={searchTheme}>
                                        
                                            <CustomTextField
                                                placeHolderText={`Search Existing Activity`}
                                                fieldName="searchActivity"
                                                fieldId="search-activity"
                                                
                                                fieldValue={this.state.searchActivity}
                                                fieldWidth={"240px"}
                                                fieldType="text"
                                                handleChange={this.handleSearchActivity}
                                                startAdornmentIcon={
                                                    <SearchIcon
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        stroke={"#888888"}
                                                        strokeWidth="0.45"
                                                        fill={"#888888"}
                                                    />
                                                }
                                            />
                                           
                                        </ThemeProvider>
                                    </Box>
                                    <Button onClick={this.props.onCancel} className={classes.textLightGreyColor} style={{ float: "right" }}> X </Button>
                                </Grid>
                            </Grid>
                            <DialogContent style={{ padding: "0px", marginTop: "24px", overflow: "hidden" }}>
                                <Grid container spacing={2} direction="row">
                                    <Grid xs={6} item>
                                        <Box marginBottom={"10px"} width={"100%"}>
                                            <Typography component={"span"} className={`${classes.fontText16} ${classes.textSecondaryColor}`} >
                                                Lesson Title
                                            </Typography>
                                            <CustomTextField
                                                placeHolderText="Enter Lesson Title"
                                                fieldId="lesson-title"
                                                fieldName="lessonTitleValue"
                                                fieldValue={lessonTitleValue}
                                                fieldWidth={"99%"}
                                                textFieldStyles={textfieldStyles}
                                                fieldType="text"
                                                isDisabled={true}
                                                handleChange={this.handleChange}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Box marginBottom={"10px"} width={"99%"}>
                                            <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                Activity Title
                                            </Typography>
                                            <CustomTextField
                                                fieldId="activity-title"
                                                placeHolderText="Enter Activity Title"
                                                fieldValue={activityTitleValue}
                                                fieldName="activityTitleValue"
                                                fieldWidth={"99%"}
                                                textFieldStyles={textfieldStyles}
                                                handleChange={this.handleChange}
                                                fieldType="text"
                                                fieldError={activityTitleError}
                                                helperText={activityTitleMsg}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            Activity Type
                                        </Typography>
                                        <Box marginLeft={"-8px"} marginBottom={"10px"} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <Box>
                                                <Radio
                                                    className={this.handleActivityTypeRadioDynamicClass("Task")}
                                                    checked={selectedActivityType === 'Task'}
                                                    onChange={this.handleActivityTypeChange}
                                                    value="Task"
                                                    name="new-activity-type-radio-button"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                />
                                                <Typography component={"span"} className={this.handleActivityTypeLabelDynamicClass("Task")}> Task </Typography>
                                                <Radio
                                                    className={this.handleActivityTypeRadioDynamicClass("Video")}
                                                    checked={selectedActivityType === 'Video'}
                                                    onChange={this.handleActivityTypeChange}
                                                    value="Video"
                                                    name="video-activity-type-radio-button"
                                                    inputProps={{ 'aria-label': 'B' }}
                                                />
                                                <Typography component={"span"} className={this.handleActivityTypeLabelDynamicClass("Video")}> Video </Typography>
                                                <Radio
                                                    className={this.handleActivityTypeRadioDynamicClass("Audio")}
                                                    checked={selectedActivityType === 'Audio'}
                                                    onChange={this.handleActivityTypeChange}
                                                    value="Audio"
                                                    name="audio-activity-type-radio-button"
                                                    inputProps={{ 'aria-label': 'C' }}
                                                />
                                                <Typography component={"span"} className={this.handleActivityTypeLabelDynamicClass("Audio")}> Audio </Typography>
                                                <Radio
                                                    className={this.handleActivityTypeRadioDynamicClass("Assessment")}
                                                    checked={selectedActivityType === 'Assessment'}
                                                    onChange={this.handleActivityTypeChange}
                                                    value="Assessment"
                                                    name="assessment-activity-type-radio-button"
                                                    inputProps={{ 'aria-label': 'D' }}
                                                />
                                                <Typography component={"span"} className={this.handleActivityTypeLabelDynamicClass("Assessment")}> Assessment </Typography>

                                            </Box>
                                        </Box>
                                    </Grid>
                                    {selectedActivityType === "Assessment" && (
                                        <>
                                            <Grid xs={12} item>
                                                <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                    Evaluation Type
                                                </Typography>
                                                <Box marginLeft={"-8px"} marginBottom={"10px"} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <Box>
                                                        <Radio
                                                            className={this.handleEvaluationTypeRadioDynamicClass("Objective")}
                                                            checked={selectedEvaluationType === 'Objective'}
                                                            onChange={this.handleEvaluationTypeChange}
                                                            value="Objective"
                                                            name="new-activity-type-radio-button"
                                                            inputProps={{ 'aria-label': 'A' }}
                                                        />
                                                        <Typography component={"span"} className={this.handleEvaluationTypeLabelDynamicClass("Objective")}> Objective </Typography>
                                                        <Radio
                                                            className={this.handleEvaluationTypeRadioDynamicClass("Subjective")}
                                                            checked={selectedEvaluationType === 'Subjective'}
                                                            onChange={this.handleEvaluationTypeChange}
                                                            value="Subjective"
                                                            name="video-activity-type-radio-button"
                                                            inputProps={{ 'aria-label': 'B' }}
                                                        />
                                                        <Typography component={"span"} className={this.handleEvaluationTypeLabelDynamicClass("Subjective")}> Subjective </Typography>
                                                        <Radio
                                                            className={this.handleEvaluationTypeRadioDynamicClass("Manual")}
                                                            checked={selectedEvaluationType === 'Manual'}
                                                            onChange={this.handleEvaluationTypeChange}
                                                            value="Manual"
                                                            name="audio-activity-type-radio-button"
                                                            inputProps={{ 'aria-label': 'C' }}
                                                        />
                                                        <Typography component={"span"} className={this.handleEvaluationTypeLabelDynamicClass("Manual")}> Manual </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {this.forObjectiveAssessmentExam() && (
                                                <Grid xs={12} item>
                                                    <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                        Objective Type
                                                    </Typography>
                                                    <Box marginLeft={"-8px"} marginBottom={"10px"} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <Box>
                                                            <Radio
                                                                className={this.handleObjectiveTypeRadioDynamicClass("Selection")}
                                                                checked={selectedObjectiveType === 'Selection'}
                                                                onChange={this.handleObjectiveTypeChange}
                                                                value="Selection"
                                                                name="objective-type-radio-button"
                                                                inputProps={{ 'aria-label': 'A' }}
                                                            />
                                                            <Typography component={"span"} className={this.handleObjectiveTypeLabelDynamicClass("Selection")}> Selection (MCQ) </Typography>
                                                            <Radio
                                                                className={this.handleObjectiveTypeRadioDynamicClass("Written")}
                                                                checked={selectedObjectiveType === 'Written'}
                                                                onChange={this.handleObjectiveTypeChange}
                                                                value="Written"
                                                                name="objective-type-radio-button"
                                                                inputProps={{ 'aria-label': 'B' }}
                                                            />
                                                            <Typography component={"span"} className={this.handleObjectiveTypeLabelDynamicClass("Written")}> Written (One word/sentence) </Typography>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            )}
                                            <Grid xs={6} item>
                                                <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                    Grading Component
                                                </Typography>
                                                <Box marginBottom={"10px"}>
                                                    <CustomSelectDropdown
                                                        fieldId="gradingComponentDropdown"
                                                        handleChange={this.handleDropdownChange}
                                                        fieldName="selectedGradingComponent"
                                                        fieldValue={selectedGradingComponent}
                                                        fieldError={false}
                                                        fieldMenuItems={gradingComponentData}
                                                        renderValue={this.renderGradingComponentDropdownValue}
                                                        fieldStyles={dropdownStyle}
                                                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                                                    />
                                                </Box>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid xs={12} item>
                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                            XAPI
                                        </Typography>
                                        <Box marginBottom={"10px"} style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                            <CustomCheckBoxComponent
                                                fieldId={"add-new-activity-xapi-checkbox"}
                                                checked={selectXAPI}
                                                checkedIcon={<CheckboxCheckIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#fff"} strokeWidth="1.5" fill={"#FBD82C"} />}
                                                uncheckIcon={<CheckboxUnCheckIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#FBD82C"} strokeWidth="1.5" fill={"none"} />}
                                                handleChange={this.handleXAPIChange}
                                            />
                                            <Typography component={"span"} className={`${classes.fontText14} ${classes.fontBold400} ${classes.textCommonColor}`} >
                                                XAPI type of content to be included
                                            </Typography>
                                        </Box>
                                        {selectXAPI && (
                                            <Box>
                                                <TextField
                                                    value={this.state.pastUrlValue}
                                                    variant="outlined"
                                                    className={`${classes.bgColor} ${classes.browseTextField}`}
                                                    placeholder='Paste URL'
                                                    onChange={this.handlePasteUrlValue}
                                                    inputProps={{
                                                        className: `${classes.textfieldInput} ${classes.textFieldFont} ${classes.textPrimaryColor}`,
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: "12px",
                                                        },
                                                    }}
                                                    error={xampiFileError}
                                                    helperText={xampiFileErrorMsg}
                                                />
                                            </Box>
                                        )}
                                    </Grid>

                                    <Grid xs={12} className={classes.dividerGrid} item>
                                        <Box className={classes.dividerLine} />
                                    </Grid>
                                    {this.forAssessmentObjective() && ( 
                                        <>
                                            <Grid xs={12} item>
                                                <Box display={"flex"} flexDirection={"row"} gridGap={"12px"} justifyContent={"start"} alignItems={"center"}>
                                                    <Box>
                                                        <Typography className={this.handleShowCorrectAnswerDynamicClass()}>
                                                            Show Correct Answers
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <this.AntSwitch checked={showCorrectAnswers} onChange={this.handleShowCorrectAnswersChange} name="showCorrectAnswers" />
                                                    </Box>
                                                    <Box>
                                                        <Typography className={`${classes.fontText15} ${classes.textCommonColor}`}>
                                                            Hide Correct Answers
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </>
                                    )}
                                    {this.forAnyEvaluationType() && (
                                        <>
                                            <Grid xs={6} item>
                                                <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                    Allowed Attempts 
                                                </Typography>
                                                <Box marginBottom={"10px"}>
                                                    <CustomSelectDropdown
                                                        fieldId="allowedAttemptsDropdown"
                                                        handleChange={this.handleDropdownChange}
                                                        fieldName="selectedAllowedAttempts"
                                                        fieldValue={selectedAllowedAttempts}
                                                        fieldError={selectedAllowedAttemptsError}
                                                        helperText={selectedAllowedAttemptsErrorMsg} 
                                                        fieldMenuItems={allowedAttemptsData}
                                                        renderValue={this.renderAllowedAttemptsDropdownValue}
                                                        fieldStyles={dropdownStyle}
                                                        iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                                                    />
                                                </Box>
                                            </Grid>
                                            {(selectedGradingComponent === "Exam" || selectedGradingComponent === "Project") && (
                                                <Grid xs={6} item>
                                                    <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                        Duration
                                                    </Typography>
                                                    <Box marginBottom={"10px"}>
                                                        <CustomSelectDropdown
                                                            fieldId="durationDropdown"
                                                            handleChange={this.handleDropdownChange}
                                                            fieldName="selectedDurationValue"
                                                            fieldValue={selectedDurationValue}
                                                            fieldError={false}
                                                            fieldMenuItems={allowedAttemptsData}
                                                            renderValue={this.renderAllowedAttemptsDropdownValue}
                                                            fieldStyles={dropdownStyle}
                                                            iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                                                        />
                                                    </Box>
                                                </Grid>
                                            )}
                                            {this.forAnyGradingComponent() && (
                                                <Grid xs={6} item>
                                                    <Box marginBottom={"10px"} width={"99%"}>
                                                        <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                            Max Score
                                                        </Typography>
                                                        <CustomTextField
                                                            fieldId="activity-max-score"
                                                            placeHolderText="Enter Max Score"
                                                            fieldValue={maxScoreValue}
                                                            fieldName="maxScoreValue"
                                                            fieldWidth={"99%"}
                                                            textFieldStyles={textfieldStyles}
                                                            handleChange={this.handleChange}
                                                            fieldType="text"
                                                            fieldError={maxScoreError}
                                                            helperText={maxScoreErrorMsg}
                                                        />
                                                    </Box>
                                                </Grid>
                                            )}
                                            <Grid xs={6} item>
                                                <Box marginBottom={"10px"} width={"99%"}>
                                                    <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                        Due Date
                                                    </Typography>
                                                    <CustomDatepicker
                                                        disableFutureDate={false}
                                                        disablePastDate={true}
                                                        placeHolderText={"Select Due Date"}
                                                        format="DD/MM/YYYY"
                                                        fieldError={dueDateError}
                                                        fieldErrorMsg={dueDateErrorMsg}
                                                        fieldStyles={dueDateStyle}
                                                        isDisabled={false}
                                                        fieldId={"due-date"}
                                                        selectedDate={dueDateValue}
                                                        handleChangeDate={(date: any) =>
                                                            this.handleDueDateChange(date)
                                                        }
                                                    />
                                                </Box>
                                            </Grid>
                                        </>
                                    )}
                                    {
                                        this.handleCheckComponentRender(
                                            selectedActivityType === "Assessment",
                                            (
                                                <Grid xs={12} item>
                                                    <Box marginBottom={"10px"} width={"100%"}>
                                                        <Typography style={{ marginBottom: "25px" }} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                            Instruction
                                                        </Typography>
                                                        <CustomTextEditor
                                                            editorId="activity-instruction-textbox"
                                                            editorContent={instructionValue}
                                                            placeHolderText="Enter Instruction"
                                                            onWysEditorStateChange={this.handleTextEditorChange}
                                                            editorError={instructionError}
                                                            helperText={instructionErrorMsg}
                                                        />
                                                    </Box>
                                                </Grid>
                                            )
                                        )
                                    }
                                    {
                                        this.handleCheckComponentRender(
                                            selectedActivityType === "Task",
                                            (
                                                <Grid xs={12} item>
                                                    <Box marginBottom={"10px"} width={"100%"}>
                                                        <Typography style={{ marginBottom: "25px" }} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                            Task
                                                        </Typography>
                                                        <CustomTextEditor
                                                            editorId="task-activity-textbox"
                                                            editorContent={taskValue}
                                                            placeHolderText="Type Task"
                                                            onWysEditorStateChange={this.handleTextEditorChange}
                                                            editorError={taskError}
                                                            helperText={taskErrorMsg}
                                                        />
                                                    </Box>
                                                </Grid>
                                            )
                                        )
                                    }
                                    {selectedActivityType === "Video" && (
                                        <Grid xs={12} item>
                                            <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                Video & Desc Placement
                                            </Typography>
                                            <Box marginBottom={"10px"} display={"flex"} gridGap={"70px"} flexWrap={"row"} justifyContent={"start"}>
                                                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} flexWrap={"no-wrap"}>
                                                    <Radio
                                                        className={this.handleVideoAudioDescPlacementClass("VideoBottomTextTop")}
                                                        checked={selectedVideoDescPlacement === 'VideoBottomTextTop'}
                                                        onChange={(event: any) => this.handleVideoDescPlacementChange(event, 'bottom', 'top')}
                                                        value="VideoBottomTextTop"
                                                        name="new-activity-type-radio-button"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                    />
                                                    <VideoBottomTextTopIcon viewBox="0 0 40 70"
                                                        stroke={this.handleTertiaryCondition(selectedVideoDescPlacement === "VideoBottomTextTop" , "#FFF" , "#888")}
                                                        fill={this.handleTertiaryCondition(selectedVideoDescPlacement === "VideoBottomTextTop" , "#FBD82C" , "none")}
                                                    />
                                                </Box>
                                                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} flexWrap={"no-wrap"}>
                                                    <Radio
                                                        className={this.handleVideoAudioDescPlacementClass("VideoTopTextBottom")}
                                                        checked={selectedVideoDescPlacement === 'VideoTopTextBottom'}
                                                        onChange={(event: any) => this.handleVideoDescPlacementChange(event, 'top', 'bottom')}
                                                        value="VideoTopTextBottom"
                                                        name="new-activity-type-radio-button"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                    />
                                                    <VideoTopTextBottomIcon viewBox="0 0 40 70"
                                                        stroke={this.handleTertiaryCondition(selectedVideoDescPlacement === "VideoTopTextBottom" , "#FFF" , "#888")}
                                                        fill={this.handleTertiaryCondition(selectedVideoDescPlacement === "VideoTopTextBottom" , "#FBD82C" , "none")}
                                                    />
                                                </Box>
                                                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"start"} flexWrap={"no-wrap"}>
                                                    <Radio
                                                        className={this.handleVideoAudioDescPlacementClass("VideoRightTextLeft")}
                                                        checked={selectedVideoDescPlacement === "VideoRightTextLeft"}
                                                        onChange={(event: any) => this.handleVideoDescPlacementChange(event, 'right', 'left')}
                                                        value="VideoRightTextLeft"
                                                        name="new-activity-type-radio-button"
                                                        inputProps={{ 'aria-label': 'C' }}
                                                    />
                                                    <VideoRightTextLeftIcon viewBox="0 0 70 40"
                                                        stroke={this.handleTertiaryCondition(selectedVideoDescPlacement === "VideoRightTextLeft" , "#FFF" , "#888")}
                                                        fill={this.handleTertiaryCondition(selectedVideoDescPlacement === "VideoRightTextLeft" , "#FBD82C" , "none")}
                                                    />
                                                </Box>
                                                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"start"} flexWrap={"no-wrap"}>
                                                    <Radio
                                                        className={this.handleVideoAudioDescPlacementClass("VideoLeftTextRight")}
                                                        checked={selectedVideoDescPlacement === "VideoLeftTextRight"}
                                                        onChange={(event: any) => this.handleVideoDescPlacementChange(event, 'left', 'right')}
                                                        value="VideoLeftTextRight"
                                                        name="new-activity-type-radio-button"
                                                        inputProps={{ 'aria-label': 'D' }}
                                                    />
                                                    <VideoLeftTextRightIcon viewBox="0 0 70 40"
                                                        stroke={this.handleTertiaryCondition(selectedVideoDescPlacement === "VideoLeftTextRight" , "#FFF" , "#888")}
                                                        fill={this.handleTertiaryCondition(selectedVideoDescPlacement === "VideoLeftTextRight" , "#FBD82C" , "none")}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )}
                                    {this.handleCheckComponentRender(
                                        selectedActivityType === "Audio",
                                        (<Grid xs={12} item>
                                            <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                Audio & Desc Placement
                                            </Typography>
                                            <Box marginBottom={"10px"} display={"flex"} gridGap={"70px"} flexWrap={"row"} justifyContent={"start"}>
                                                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} flexWrap={"no-wrap"}>
                                                    <Radio
                                                        className={this.handleVideoAudioDescPlacementClass("AudioBottomTextTop")}
                                                        checked={selectedAudioDescPlacement === 'AudioBottomTextTop'}
                                                        onChange={(event: any) => this.handleAudioDescPlacementChange(event, 'bottom', 'top')}
                                                        value="AudioBottomTextTop"
                                                        name="new-activity-type-radio-button"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                    />
                                                    <AudioBottomTextTopIcon viewBox="0 0 40 70"
                                                        stroke={this.handleTertiaryCondition(selectedAudioDescPlacement === "AudioBottomTextTop" , "#FFF" , "#888")}
                                                        fill={this.handleTertiaryCondition(selectedAudioDescPlacement === "AudioBottomTextTop" , "#FBD82C" , "none")}
                                                    />
                                                </Box>
                                                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} flexWrap={"no-wrap"}>
                                                    <Radio
                                                        className={this.handleVideoAudioDescPlacementClass("AudioTopTextBottom")}
                                                        checked={selectedAudioDescPlacement === 'AudioTopTextBottom'}
                                                        onChange={(event: any) => this.handleAudioDescPlacementChange(event, 'top', 'bottom')}
                                                        value="AudioTopTextBottom"
                                                        name="new-activity-type-radio-button"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                    />
                                                    <AudioTopTextBottomIcon viewBox="0 0 40 70"
                                                        stroke={this.handleTertiaryCondition(selectedAudioDescPlacement === "AudioTopTextBottom" , "#FFF" , "#888")}
                                                        fill={this.handleTertiaryCondition(selectedAudioDescPlacement === "AudioTopTextBottom" , "#FBD82C" , "none")}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>)
                                    )}
                                    {(selectedActivityType === "Video" || selectedActivityType === "Audio") && !selectXAPI && (
                                        <>
                                            <Grid xs={6} item>
                                                <Box marginBottom={"10px"} width={"100%"}>
                                                    <Typography component={"span"} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                        File
                                                    </Typography>
                                                    <TextField
                                                        value={this.getSelectedMediaFileName()}
                                                        variant="outlined"
                                                        className={`${classes.bgColor} ${classes.browseTextField}`}
                                                        placeholder='Upload File'
                                                        inputProps={{
                                                            className: `${classes.textfieldInput} ${classes.textFieldFont} ${classes.textPrimaryColor}`,
                                                        }}
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: "12px",
                                                            },
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <div className={`${classes.bgColor}`}>
                                                                    <input
                                                                        type="file"
                                                                        id="audio-video-file-input"
                                                                        className={`${classes.bgColor} ${classes.fileInput}`}
                                                                        onChange={this.handleMediaFileChange}
                                                                    />
                                                                    <span className={classes.browseText} onClick={this.handleMediaBrowseClick}>
                                                                        Browse File
                                                                    </span>
                                                                </div>
                                                            ),
                                                        }}
                                                        error={mediaFileError}
                                                        helperText={mediaFileErrorMsg}
                                                    />
                                                       <Typography color="error" variant="caption" className={classes.textCommonColor}>
                                                            Maximum file size: 50MB for both videos and audio files.
                                                        </Typography>
                                                </Box>
                                            </Grid>
                                        </>
                                    )}
                                    {this.handleCheckComponentRender(
                                        (selectedActivityType === "Video" || selectedActivityType === "Audio"),
                                        <>
                                            <Grid xs={12} item>
                                                <Box marginBottom={"10px"} width={"100%"}>
                                                    <Typography style={{ marginBottom: "25px" }} className={`${classes.fontText16} ${classes.textCommonColor}`} >
                                                        Description
                                                    </Typography>
                                                    <CustomTextEditor
                                                        editorId="activity-description-textbox"
                                                        editorContent={descriptionValue}
                                                        placeHolderText="Add Activity Description"
                                                        onWysEditorStateChange={this.handleTextEditorChange}
                                                        editorError={descriptionError}
                                                        helperText={descriptionErrorMsg}
                                                    />
                                                </Box>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid xs={12} item>
                                        <Box marginBottom={"10px"} style={{ width: "100%" }}>
                                            <CustomButton btnText={this.handleTertiaryCondition(isEdit,"Save","Add")} btnStyle={submitButtonStyle} buttonId={`activity-submit-button`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleSubmitForm} isDisabled={false} />
                                            <CustomButton btnText="Cancel" btnStyle={cancelButtonStyle} buttonId={`activity-canecl-button`} buttonVariant={"outlined"} type={"button"} handleButtonClick={this.props.onCancel} isDisabled={false} />
                                            {(selectedActivityType === 'Video' || selectedActivityType === 'Audio') && (
                                                <CustomButton btnText="Preview" btnStyle={previewButtonStyle} buttonId={`activity-preview-button`} buttonVariant={"outlined"} type={"button"} handleButtonClick={this.handleOpenPreviewDialog} isDisabled={false} />
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Box>
                    </Dialog>
                </Box>
                {this.handleCheckComponentRender(
                    openVideoPreviewDialog,
                    <VideoPreviewDialog
                    open={openVideoPreviewDialog}
                    onCancel={this.handleCloseVideoPreviewDialog}
                    onConfirm={this.handleCloseVideoPreviewDialog}
                    videoPosition={this.state.videoPreviewPosition}
                    textPosition={this.state.textPreviewPosition}
                    video={this.state.selectedMediaFile}
                    description={this.state.descriptionValue}
                    activityTitle={activityTitleValue}
                />
                )}

                {this.handleCheckComponentRender(
                    openAudioPreviewDialog,
                    <AudioPreviewDialog
                    open={openAudioPreviewDialog}
                    onCancel={this.handleCloseAudioPreviewDialog}
                    onConfirm={this.handleCloseAudioPreviewDialog}
                    audioPosition={this.state.audioPreviewPosition}
                    textPosition={this.state.audioTextPreviewPosition}
                    audio={this.state.selectedMediaFile}
                    description={this.state.descriptionValue}
                    handleUpdateMediaProgressTime={() => { }}
                    activityTitle={activityTitleValue}
                />
                )}

                <SearchExistingActivityModal
                    open={openExistingActivityModal}
                    onCancel={this.handleCloseExistingActivityModal}
                    onConfirm={this.handleCloseExistingActivityModal}
                    activityData={activitySearchData}
                    getCopiedActivityId={(id: any) => this.handleCopyExistingActivity(id)}
                />
            </>
        );

        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const cancelButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    border: "1px solid",
    height: "50px",
    borderRadius: "12px",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 500,
    marginLeft: "15px",
    marginTop: "15px",
    float: "right",
} as const;

const submitButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    height: "50px",
    textAlign: "center",
    fontSize: "18px",
    borderRadius: "12px",
    float: "right",
    marginTop: "15px",
    fontWeight: 500,
    marginLeft: "15px",
} as const;

const previewButtonStyle = {
    width: "120px",
    padding: "10px 10px",
    border: "1px solid",
    height: "50px",
    fontSize: "18px",
    borderRadius: "12px",
    textAlign: "center",
    marginTop: "15px",
    fontWeight: 500,
    float: "left",
    marginLeft: "15px",
} as const;

const dropdownStyle = {
    width: "100%",
    height: "50px",
    padding: "17px 15px",
    borderRadius: "12px",
    background: "#FFF",
    fontSize: "14px",
    border: "1px solid #888",
    color: "#888",
    fontWeight: 400,
    marginTop: "10px",
} as const;

const dueDateStyle = {
    width: "100%",
    marginTop: "10px",
    justifyContent: "center",
} as const

const textfieldStyles = {
    padding: "8px 0px",
    width: "99%",
    marginTop: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AddNewActivityModal);
// Customizable Area End
