// Customizable Area Start
import React from "react";
import {
  AppBar,
  Box,
  Grid,
  Hidden,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TeacherCoursesMainController, {
  Props,
} from "./TeacherCoursesMainController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import TabPanel from "../../../components/src/TabPanel.web";
import StudentProgressJourneyDesktop from "../src/StudentProgressJourneyDesktop.web";
import StudentProgressJourneyMobileView from "../src/StudentProgressJourneyMobileView.web";
import TeacherParticipantsTab from "./TeacherParticipantsTab.web";
import TeacherCoursesTab from "./TeacherCoursesTab.web";
import CustomButton from "../../../components/src/CustomButton.web";
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    mainContainerPadding: {
      padding: "40px",
      height: "auto !important",
    },
    coursesBreadcrumb: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    courseBoxRoot: {
      marginTop: "30px",
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.spacing(2.5, 2.5, 2.5, 2.5),
      height: "80vh",
    },
    courseDetailMainContainer: {
      maxHeight: "700vh",
      overflowX: "auto",
      overflowY: "hidden",
      borderRadius: "10px",
    },
    coursesActiveTab: {
      color: "#0D46BC",
      fontWeight: 500,
      fontSize: 20,
      textTransform: "capitalize",
    },
    coursesTabLables: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "23px",
      textTransform: "capitalize",
      padding: theme.spacing(2.5, 0),
    },
    coursesNameBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    coursesAppBar: {
      borderRadius: "12px 12px 0 0",
      backgroundColor: "#ffffff",
      borderBottom: "1px solid #f1f1f1",
    },
    marginRightBoxs: {
      marginRight: "8px",
    },
    fontsBold700: {
      fontWeight: 700,
    },
    coursesTabButtons: {
      backgroundColor: "transparent",
      padding: "10px 4px",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
      display: "inline-block",
      fontSize: "16px",
      opacity: 1,
      width: "100%",
      "&.Mui-selected": {
        backgroundColor: "transparent",
        color: "#0D46BC !important",
        outline: "none",
        borderBottom: "1px solid #0D46BC",
        fontWeight: 600,
      },
      "&.MuiTab-root": {
        minHeight: "60px",
      },
    },
    coursesDarkModeBtn: {
      backgroundColor: "transparent",
      padding: "10px 4px",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
      display: "inline-block",
      fontSize: "16px",
      opacity: 1,
      width: "100%",
      "&.Mui-selected": {
        backgroundColor: "transparent",
        color: "#FBD82C !important",
        outline: "none",
        borderBottom: "1px solid #FBD82C",
        fontWeight: 600,
      },
      "&.MuiTab-root": {
        minHeight: "60px",
      },
    },
    tabPanel: {
      padding: "0px 0px 20px 0px",
      borderRadius: "12px",
      "& #simple-tabpanel-0": {
        minHeight: "90vh",
      },
      "& #simple-tabpanel-1": {
        minHeight: "90vh",
      },
      "& #simple-tabpanel-2": {
        minHeight: "90vh",
      }
    },
    tabs: {
      width: "100%",
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          justifyContent: "center",
          borderBottom: "none",
        }
      }
    },
  });
// Customizable Area End

// Customizable Area Start
export class TeacherCoursesMain extends TeacherCoursesMainController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      coursesTabValue,
      selectCourseSubject,
      selectedCourseData,
      selectedLessonName,
      selectedTopicName,
      loadCourseTab,
      openAddActivityModal,
      selectedClassDDValue,
      coursesClassDDComponentValues,
      currentRole
    } = this.state;
    return (
      <Box className={`${classes.mainContainerPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        data-test-id="CoursesMainBox"
      >
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box className={classes.coursesBreadcrumb}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText14}`}
                component={"span"}
                data-test-id="CoursesMainBoxBd"
              >
                {" Courses "}
              </Typography>
              {this.state.coursesTabValue === 1 && (
                <>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText14}`}
                    component={"span"}
                    data-test-id="Bread_two"
                  >
                    / {(selectedCourseData?.courseName || "").trim() }
                  </Typography>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText14}`}
                    component={"span"}
                    data-test-id="Bread_three"
                  >
                    {" " + "/"} {" Journey "}
                  </Typography>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText14}`}
                    component={"span"}
                    data-test-id="Bread_three"
                  >
                    / {" " + selectedTopicName + " "}
                  </Typography>
                  <Typography
                    className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText12}`}
                    component={"span"}
                    data-test-id="Bread_four"
                  >
                    / {" " + selectedLessonName}
                  </Typography>
                </>
              )}
              {this.state.coursesTabValue === 2 && (
                <>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText14}`}
                    component={"span"}
                    data-test-id="Bread_two"
                  >
                    / {" " + selectedCourseData?.courseName}
                  </Typography>
                  <Typography
                    className={`${classes.primaryColor5} ${classes.fontBold600} ${classes.fontText14}`}
                    component={"span"}
                    data-test-id="Bread_three"
                  >
                    / {" Participants"}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid
            className={classes.pageName}
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="space-between"
            data-test-id="BoxGrid"
          >
            <Box display={"flex"} alignItems={"center"}>
              <Typography
                className={`${classes.fontText34} ${classes.textPrimaryColor} ${classes.fontsBold700}`}
                component={"span"}
                data-test-id="HeaderName"
              >
                {selectedCourseData?.courseName || "Courses"}
              </Typography>
            </Box>
            {coursesTabValue === 1 && currentRole !== "parent" &&
              <CustomButton
                btnText="Add New Activities"
                btnStyle={addNewActivityBtn}
                buttonId={`add new activity button`}
                buttonVariant={"contained"}
                type={"button"}
                handleButtonClick={this.handleOpenAddNewActivityModal}
                isDisabled={false}
              />
             
            }
          </Grid>
          <Grid item xs={12} container data-test-id="Header_DD">
{coursesTabValue !== 1 &&
            <CustomSelectDropdown
              fieldId="statusDropdown"
              fieldName="selectedClassDDValue"
              fieldValue={selectedClassDDValue}
              fieldStyles={coursesDDStyles}
              handleChange={this.handleChangeClassDropdownValue}
              renderValue={this.renderCoursesClassDropdownValue}
              iconComponent={
                <DropdownIcon
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke={"#888888"}
                  strokeWidth="1.5"
                  fill={"none"}
                />
              }
              fieldMenuItems={coursesClassDDComponentValues}
              fieldError={false}
            />
}
          </Grid>
          <Grid item xs={12}>
            <Grid direction="row" className={`${classes.courseDetailMainContainer} ${classes.bgColor}`} container>
              <Tabs
                data-test-id="coursesTabs"
                variant="fullWidth"
                scrollButtons="auto"
                className={`${classes.tabs}`}
                TabIndicatorProps={{
                  style: {
                    backgroundColor:this.handleCondition(localStorage.getItem('dark_mode') === 'true', "#FBD82C"," #0D46BC")
                  },
                }}
                classes={{ indicator: classes.coursesActiveTab }}
                value={coursesTabValue}
                onChange={this.handleTabChange}
              >
                <Tab
                  id="Courses"
                  classes={{
                    root:
                      coursesTabValue === 0
                        ? classes.coursesActiveTab
                        : classes.coursesTabLables,
                  }}
                  label={"Courses"}
                  className={`${classes.textSecondaryColor} ${this.handleCondition(localStorage.getItem("dark_mode")==="true",classes.coursesDarkModeBtn,classes.coursesTabButtons)}`}
                />
                <Tab
                  id="journey"
                  classes={{
                    root:
                      coursesTabValue === 1
                        ? classes.coursesActiveTab
                        : classes.coursesTabLables,
                  }}
                  label={"Journey"}
                  className={`${classes.textSecondaryColor}  ${this.handleCondition(localStorage.getItem("dark_mode")==="true",classes.coursesDarkModeBtn,classes.coursesTabButtons)}`}
                />
                <Tab
                  id="participants"
                  classes={{
                    root:
                      coursesTabValue === 2
                        ? classes.coursesActiveTab
                        : classes.coursesTabLables,
                  }}
                  label={"Participants"}
                  className={`${classes.textSecondaryColor}  ${this.handleCondition(localStorage.getItem("dark_mode")==="true",classes.coursesDarkModeBtn,classes.coursesTabButtons)}`}
                />
              </Tabs>
            </Grid>
            <Grid className={`${classes.tabPanel} ${classes.bgColor}`} item xs={12}>
              <TabPanel value={coursesTabValue} index={0}>
                {loadCourseTab && coursesClassDDComponentValues.length > 0 && selectedClassDDValue &&
                  <TeacherCoursesTab
                    navigation={navigation}
                    openCourseJourney={(rowData: any) => this.openJourneyForCourse(rowData)}
                    classId={selectedClassDDValue}
                    classesList={coursesClassDDComponentValues}
                    currentRole={this.state.currentRole}
                  />
                }
              </TabPanel>
              <TabPanel value={coursesTabValue} index={1}>
                <Grid container direction="row">
                  {/* Topic Lessons Activity Box */}
                  <Hidden only={["xs", "sm"]}>
                    <Grid item xs={12}>
                      <StudentProgressJourneyDesktop 
                        handleSetLessonName={this.handleSetLessonName}
                        navigation={navigation}
                        isActivityWrite={true}
                        handleSetTopicName={this.handleSetTopicName}
                        selectedSubjectName={selectCourseSubject}
                        courseData={selectedCourseData}
                        openActivityModalFromOutside={openAddActivityModal}
                        classId={selectedClassDDValue}
                        isParent={currentRole === "parent"}
                        isParentTeacherCourse={currentRole === "parent teacher"}
                      />
                    </Grid>
                  </Hidden>
                  <Hidden only={["md", "lg", "xl"]}>
                    <Grid item xs={12}>
                      <StudentProgressJourneyMobileView
                        selectedSubjectName={selectCourseSubject}
                        handleSetTopicName={this.handleSetTopicName}
                        navigation={navigation}
                        handleSetLessonName={this.handleSetLessonName}
                        courseData={selectedCourseData}
                      />
                    </Grid>
                  </Hidden>
                </Grid>
              </TabPanel>
              <TabPanel value={coursesTabValue} index={2}>
                <TeacherParticipantsTab courseTab={true}/>
              </TabPanel>
            </Grid>
          </Grid>
        </Grid>

      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const coursesDDStyles = {
  width: "17%",
  marginTop: "10px",
  marginBottom: "40px",
} as const;

const addNewActivityBtn = {
  width: "max-content",
  textAlign: "center",
  height: "50px",
  marginBottom: "25px",
  fontSize: "18px",
  borderRadius: "12px",
  padding: "10px 15px",
  fontWeight: 500,
  float: "right",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherCoursesMain);
// Customizable Area End
