// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import TenantAdminSetupGradeController, {
  Props,
} from "./TenantAdminSetupGradeController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import CustomTable, {
  tableColumnTypes,
} from "../../../components/src/CustomTable.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import NewGradeDialog from "./NewGradeDialog.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";
import DeleteGradeConfirmModalWeb from "./DeleteGradeConfirmModal.web";
// Customizable Area End

// Customizable Area Start
const tenantGradeColumn = [
  {
    id: "grade",
    columnId: 1,
    label: "Grade",
    type: tableColumnTypes.TEXT,
  },
];

const styles = (theme: Theme) =>
  createStyles({
    searchBox: {
      display: "flex",
      alignItems: "flex-end",
      padding: theme.spacing(0, 3),
      margin: theme.spacing(4, 0),
    },
    inputLabel: {
      fontSize: 14,
      fontWeight: 400,
      color: "#888888",
      marginBottom: "6px",
    },
    searchBtn: {
      borderRadius: "12px",
      backgroundColor: "#0D46BC",
      color: "#ffffff",
      padding: "13px 30px",
      marginBottom: "-20px",
      textTransform: "capitalize",
      fontSize: 18,
      fontWeight: 500,
    },
    filterBox: {
      marginRight: "20px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
    },
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  });

const gradeTableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
    MuiIconButton: {
      root: {
        padding: "0px !important",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class TenantAdminSetupGrade extends TenantAdminSetupGradeController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, openNewGradeDialog } = this.props;
    const {
      selectGradeItems,
      selectedGrade,
      gradesList,
      openEditNewGradePopup,
      selectedGradeData,
      gradesTableActionButtonConfig,
      openDeleteGradePopup,
      gradePermission
    } = this.state;

    return (
      <Box
        style={{ width: "100%", paddingTop: "1px" }}
        className={`${classes.bgColor}`}
      >
        <Grid container className={`${classes.bgColor} ${classes.searchBox}`}>
          <Grid item xs={12} sm={6} md={3}>
            <Box className={`${classes.filterBox}`}>
              <Typography className={classes.inputLabel}>Grade</Typography>
              <CustomSelectDropdown
                fieldId="select-grade-dropdown"
                fieldValue={selectedGrade}
                fieldName="selectedGrade"
                handleChange={this.handleDropDownValueChange}
                renderValue={this.renderGradeDropDownValue}
                fieldMenuItems={selectGradeItems}
                placeHolderText="Select Grade"
                fieldStyles={{ width: "95%" }}
              />
            </Box>
          </Grid>
          <CustomButtonWeb
            buttonVariant="contained"
            btnStyle={{
              padding: "10px 10px",
              borderRadius: "10px",
              width: "130px",
              fontWeight: 600,
              fontSize: "16px",
              textAlign: "center",
            }}
            btnText="Search"
            buttonId="grades-filter-search"
            handleButtonClick={() => this.handleGetGradesList()}
            isDisabled={false}
            type={"button"}
          />
        </Grid>
        <Box
          style={{ height: "4px", backgroundColor: "#F1F1F1", width: "100%" }}
        />
        <Box
          className={`${classes.customTableStyles} ${classes.classManagementTable} ${classes.bgColor}`}
          p={"0px 20px"}
        >
          <ThemeProvider theme={gradeTableTheme}>
            <CustomTable
              tableId={"tenant-grade-table"}
              tableHeaderColumns={tenantGradeColumn}
              tableBodyData={gradesList}
              showMenuIcon={gradePermission?.grade?.write}
              actionButtonConfig={gradesTableActionButtonConfig}
            />
          </ThemeProvider>
        </Box>
        {(openEditNewGradePopup || openNewGradeDialog) && (
          <NewGradeDialog
            open={openEditNewGradePopup || openNewGradeDialog}
            handleClose={() => this.handleCloseEditGradePopup()}
            handleAddGrade={(data: any) => this.handleAddNewGrade(data)}
            handleEditGrade={(data: any) => this.handleUpdateGrade(data)}
            selectedGradeData={selectedGradeData}
            isEdit={openEditNewGradePopup}
          />
        )}
        <DeleteGradeConfirmModalWeb
          open={openDeleteGradePopup}
          gradeName={selectedGradeData?.grade || ""}
          onCancel={() => this.handleCloseDeleteGradeConfirmModal()}
          onConfirm={() => this.handleDeleteGrade()}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantAdminSetupGrade);
// Customizable Area End
