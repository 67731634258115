// Customizable Area Start
import React from "react";
import { Box, Typography } from '@material-ui/core';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import WebSidebarController, { Props } from "./WebSidebarController.web";
import DashboardIcon from "./SVGIcons/DashboardIcon.web";
import MyGradebookIcon from "./SVGIcons/MyGradebookIcon.web";
import MyAssessmentIcon from "./SVGIcons/MyAssessmentIcon.web";
import MyPathIcon from "./SVGIcons/MyPathIcon.web";
import MyCoursesIcon from "./SVGIcons/MyCoursesIcon.web";
import { customThemeStyles } from "./CustomThemeStyles.web";
import { combineStyles } from "./CombineStyles.web";
import GearIcon from "./SVGIcons/GearIcon.web";
import UserIcon from "./SVGIcons/UserIcon.web";
import ReadIcon from "./SVGIcons/ReadIcon.web";
import RolesPermissionsIcon from "./SVGIcons/RolesPermissionsIcon.web";
import AppearanceMngIcon from "./SVGIcons/AppearanceMngIcon.web";
import { USER_ROLES } from "./CommonType.web";
// Customizable Area End

// Customizable Area Start


const handleSetSidebarIOptionColor = (isDarkMode: boolean, matchWindowPathString: any) => {
    const urlPath = new URL(document.URL);    
    const currentPath = urlPath.pathname.toLowerCase();
    if(typeof matchWindowPathString === "object") {
        if (matchWindowPathString.includes(urlPath.pathname)) {
            return  isDarkMode ? "#ffffff" :'#0D46BC' ;
        } 
    }else {

        const matchString = `/${matchWindowPathString.toLowerCase()}`; 
        if (currentPath === matchString) {
            return  isDarkMode ? "#ffffff" :'#0D46BC' ;
        }    
        if (currentPath.startsWith(matchString) && currentPath.length > matchString.length) {
            return isDarkMode ? "#ffffff"  : '#0D46BC'; 
           }
    }
    return  isDarkMode  ? "#EBEBEB":"#090909"; 
};

const handleSetIconStrokeWidth = (matchWindowPathString: string, isAlreadyFixedIconWidth?: boolean) => {
    if (window.location.pathname?.toLowerCase().includes(matchWindowPathString?.toLowerCase())) {
        if (isAlreadyFixedIconWidth) return 1.5;
        return 2.5;
    }
    else {
        if (isAlreadyFixedIconWidth) return 0.055;
        return 1.0;
    }
}

const studentSidebarMenuItems = [
    {
        id: 1,
        mainMenuItemText: "GETTING STARTED",
        isSubMenuPresent: true,
        subMenuItems: [
            {
                id: 1,
                menuItemText: "My Dashboard",
                isSubMenuPresent: false,
                screenName: "StudentDashboard",
                toMatch: "studentdashboard",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <DashboardIcon width="24" height="24" viewBox="0 0 24 24" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            }
        ]
    },
    {
        id: 2,
        mainMenuItemText: "WORKSPACE",
        isSubMenuPresent: true,
        subMenuItems: [
            {
                id: 1,
                menuItemText: "My Courses",
                isSubMenuPresent: false,
                screenName: "StudentMyCourses",
                toMatch: "Student/my-courses",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyCoursesIcon width="28" height="18" viewBox="0 0 28 18" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString, true)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 2,
                menuItemText: "My Path",
                isSubMenuPresent: false,
                screenName: "StudentMyPath",
                toMatch: "Student/my-path",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyPathIcon width="22" height="23" viewBox="0 0 22 23" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={"0.055"} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 3,
                menuItemText: "My Assessments",
                isSubMenuPresent: false,
                screenName: "StudentMyAssessments",
                toMatch: "Student/my-assessments",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyAssessmentIcon width="26" height="26" viewBox="0 0 26 26" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString, true)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 4,
                menuItemText: "My Grade Book",
                isSubMenuPresent: false,
                screenName: "StudentMyGradebook",
                toMatch: "Student/my-gradebook",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyGradebookIcon width="20" height="26" viewBox="0 0 20 26" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={"1.0"} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            }
        ]
    }
];

export const tenantAdminSetup = [
    {
        id: 1,
        mainMenuItemText: "GETTING STARTED",
        isSubMenuPresent: true,
        subMenuItems: [
            {
                id: 1,
                menuItemText: "Dashboard",
                isSubMenuPresent: false,
                screenName: "TenantDashboardMain",
                toMatch: "TenantDashboard",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <DashboardIcon width="24" height="24" viewBox="0 0 24 24" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            }
        ]
    },
    {
        id: 2,
        mainMenuItemText: "MANAGE HUB",
        isSubMenuPresent: true,
        subMenuItems: [
            {
                id: 1,
                menuItemText: "Setup",
                isSubMenuPresent: false,
                permissionKeys: [
                    "school_year",
                    "subject",
                    "school_calender",
                    "curriculum",
                    "class_management",
                    "teacher_management",
                    "gradebook"
                ], 
                screenName: "TenantAdminSetupDashboard",
                toMatch: "TenantAdminSetupDashboard",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <GearIcon width="28" height="18" viewBox="0 0 28 18" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString, true)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 2,
                menuItemText: "User Management",
                isSubMenuPresent: false,
                screenName: "TenantUserManagement",
                toMatch: "Tenant/UserManagement",
                permissionKeys: [
                    "user_management_student_parent",
                    "user_management_teacher_parent_teacher",
                    "user_management_others"
                ], 
                disabled: false,
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <UserIcon width="22" height="23" viewBox="0 0 22 23" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString, true)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 3,
                menuItemText: "Course Management ",
                isSubMenuPresent: false,
                screenName: "CourseManagementMain",
                toMatch: "CourseManagement",
                permissionKeys: [
                    "course_management_course_creation", 
                    "catalogue_management_creation",
                ], 
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyCoursesIcon width="28" height="18" viewBox="0 0 28 18" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString, true)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 4,
                menuItemText: "Roles & Permissions",
                isSubMenuPresent: false,
                screenName: "TenantRolesAndPermissions",
                permissionKeys: [
                    "roles_creation",
                ], 
                toMatch: "TenantRolesAndPermissions",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <RolesPermissionsIcon width="26" height="25" viewBox="0 0 26 25" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString, true)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 5,
                menuItemText: "Appearance Management",
                isSubMenuPresent: false,
                screenName: "TenantAppearanceManagement",
                toMatch: "Tenant/AppearanceManagement",
                permissionKeys: [
                    "appreance_management",
                    "appreance_management_2",
                ], 
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <AppearanceMngIcon viewBox="0 0 512 512" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString, true)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            }
        ]
    }
]; 

const teacherDashboard = [
    {
        id: 1,
        mainMenuItemText: "GETTING STARTED",
        isSubMenuPresent: true,
        subMenuItems: [
            {
                id: 1,
                menuItemText: "Dashboard",
                isSubMenuPresent: false,
                screenName: "TeacherDashboard",
                toMatch: "teacherdashboard",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <DashboardIcon width="24" height="24" viewBox="0 0 24 24" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            }
        ]
    },
    {
        id: 2,
        mainMenuItemText: "WORKSPACE",
        isSubMenuPresent: true,
        subMenuItems: [
            {
                id: 1,
                menuItemText: "Students",
                isSubMenuPresent: false,
                screenName: "StudentTeacher",
                toMatch: "student",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <ReadIcon width="22" height="25" viewBox="0 0 22 25" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="0.055" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 2,
                menuItemText: "Courses",
                isSubMenuPresent: false,
                screenName: "CoursesMain",
                toMatch: "mycourses",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyCoursesIcon width="28" height="18" viewBox="0 0 28 18" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString,true)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 3,
                menuItemText: "Calendar",
                isSubMenuPresent: false,
                screenName: "TeacherCalReshedulling",
                toMatch: "teachercalendar",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyPathIcon width="22" height="23" viewBox="0 0 22 23" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString, true)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 4,
                menuItemText: "Assessment",
                isSubMenuPresent: false,
                screenName: "StudentPerformanceAssessment",
                toMatch: "assessment",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyAssessmentIcon width="26" height="26" viewBox="0 0 26 26" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="1.0" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 5,
                menuItemText: "Grade Book",
                isSubMenuPresent: false,
                screenName: "TeacherGradebook",
                toMatch: "Teacher/Gradebook",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyGradebookIcon width="20" height="26" viewBox="0 0 20 26" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="1.0" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            }
        ]
    },
    {
        id: 3,
        mainMenuItemText: "MANAGE HUB",
        isSubMenuPresent: true,
        subMenuItems: [
            {
                id: 1,
                menuItemText: "Course Management",
                isSubMenuPresent: false,
                screenName: "CourseManagementMain",
                toMatch: "CourseManagement",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyCoursesIcon width="28" height="18" viewBox="0 0 28 18" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString, true)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
        ]
    }
];

const parentTeacherSidebarMenuItems = [
    {
        id: 1,
        mainMenuItemText: "GETTING STARTED",
        isSubMenuPresent: true,
        subMenuItems: [
            {
                id: 1,
                menuItemText: "Dashboard",
                isSubMenuPresent: false,
                screenName: "ParentTeacherDashboard",
                toMatch: "parentteacher/dashboard",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <DashboardIcon width="24" height="24" viewBox="0 0 24 24" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="1.5" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            }
        ]
    },
    {
        id: 2,
        mainMenuItemText: "WORKSPACE",
        isSubMenuPresent: true,
        subMenuItems: [
            {
                id: 1,
                menuItemText: "Students",
                isSubMenuPresent: false,
                screenName: "StudentPage",
                toMatch: "Student",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <ReadIcon width="22" height="25" viewBox="0 0 22 25" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="0.055" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 2,
                menuItemText: "Courses",
                isSubMenuPresent: false,
                screenName: "CoursesMain",
                toMatch: "mycourses",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyCoursesIcon width="28" height="18" viewBox="0 0 28 18" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="0.055" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 3,
                menuItemText: "Calendar",
                isSubMenuPresent: false,
                screenName: "ParentTeacherCalendar",
                toMatch: "ParentTeacher/Calendar",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyPathIcon width="22" height="23" viewBox="0 0 22 23" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="0.055" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 4,
                menuItemText: "Assessment",
                isSubMenuPresent: false,
                screenName: "ParentTeacherAssessments",
                toMatch: "ParentTeacher/Assessments",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyAssessmentIcon width="26" height="26" viewBox="0 0 26 26" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="1.0" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 5,
                menuItemText: "Grade Book",
                isSubMenuPresent: false,
                screenName: "ParenTeacherGradebook",
                toMatch: "Teacher/Gradebook",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyGradebookIcon width="20" height="26" viewBox="0 0 20 26" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="1.0" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            }
        ]
    },
    {
        id: 3,
        mainMenuItemText: "MANAGE HUB",
        isSubMenuPresent: true,
        subMenuItems: [
            {
                id: 1,
                menuItemText: "Course Management",
                isSubMenuPresent: false,
                screenName: "CourseManagementMain",
                toMatch: "CourseManagement",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyCoursesIcon width="28" height="18" viewBox="0 0 28 18" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="0.055" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            }
        ]
    },
];

const registrarSidebarItems = [
    {
        id: 1,
        mainMenuItemText: "WORKSPACE",
        isSubMenuPresent: true,
        subMenuItems: [
            {
                id: 1,
                menuItemText: "Students",
                isSubMenuPresent: false,
                screenName: "RegistrarStudentsList",
                toMatch: "Registrar/Student",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <ReadIcon width="22" height="25" viewBox="0 0 22 25" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="0.055" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 2,
                menuItemText: "Grade Book",
                isSubMenuPresent: false,
                screenName: "RegistrarGradebook",
                toMatch: "Registrar/Gradebook",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyGradebookIcon width="20" height="26" viewBox="0 0 20 26" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString, true)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            }
        ]
    }
];

const parentDashboard = [
    {
        id: 1,
        mainMenuItemText: "GETTING STARTED",
        isSubMenuPresent: true,
        subMenuItems: [
            {
                id: 1,
                menuItemText: "Dashboard",
                isSubMenuPresent: false,
                screenName: "ParentDashboard",
                toMatch: "ParentDashboard",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <DashboardIcon width="24" height="24" viewBox="0 0 24 24" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            }
        ]
    },
    {
        id: 2,
        mainMenuItemText: "WORKSPACE",
        isSubMenuPresent: true,
        subMenuItems: [
            {
                id: 1,
                menuItemText: "Students",
                isSubMenuPresent: false,
                screenName: "ParentStudent",
                toMatch: "ParentStudent",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <ReadIcon width="22" height="25" viewBox="0 0 22 25" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="0.055" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 2,
                menuItemText: "Courses",
                isSubMenuPresent: false,
                screenName: "TeacherCoursesMain",
                toMatch: "MyCourses",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyCoursesIcon width="28" height="18" viewBox="0 0 28 18" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString,true)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 3,
                menuItemText: "Calendar",
                isSubMenuPresent: false,
                screenName: "ParentCalendar",
                toMatch: "Parent/Calendar",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyPathIcon width="22" height="23" viewBox="0 0 22 23" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth={handleSetIconStrokeWidth(matchWindowPathString, true)} fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 4,
                menuItemText: "Assessment",
                isSubMenuPresent: false,
                screenName: "ParentAssessment",
                toMatch: "Parent/Assessment",
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyAssessmentIcon width="26" height="26" viewBox="0 0 26 26" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="1.0" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            },
            {
                id: 5,
                menuItemText: "Grade Book",
                isSubMenuPresent: false,
                screenName: "ParentGradebook",
                toMatch: ["/Parent/Gradebook", "/Teacher/GradebookGradesDetail"],
                iconComponent: (isDarkMode: boolean, matchWindowPathString: string) => <MyGradebookIcon width="20" height="26" viewBox="0 0 20 26" stroke={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} strokeWidth="1.0" fill={handleSetSidebarIOptionColor(isDarkMode, matchWindowPathString)} />
            }
        ]
    },
];



const styles = (theme: Theme) =>
    createStyles({
        listitem: {
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            fontSize: "16px"
        },
        subMenuItem: {
            marginLeft: "20px",
            cursor: "pointer",
            whiteSpace: "pre-wrap",
            fontSize: "16px"
        },
        sidebarGrid: {
            position: "fixed",
            left: 0,
            top: "85px",
            padding: "16px 5px",
            bottom: 0,
            overflowY: "auto",
            overflowX: "hidden",
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 0,
            transition: "width 0.4s ease-out",
            zIndex: 1
        },
        mainMenuItemText: {
            fontSize: "16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            [theme.breakpoints.down('xs')]: {
                fontSize: "12px",
            },
        },
        subMenuItemBox: {
            cursor: "pointer"
        },
        mainMenuItemHidden: {
            opacity: 0,
            visibility: "hidden",
            transition: "visibility 0.3s ease-out",
        },
        watermarkBox: {
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            alignItems: "end",
        },
        watermark: {
            position: "relative",
            bottom: theme.spacing(0),
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(5),
            paddingBottom: theme.spacing(2),
            fontSize: "14px",
            fontWeight: 400,
            color: " #8888",
            lineHeight: "20px",
        }
    })
// Customizable Area End

export class WebSidebar extends WebSidebarController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    getRoleSidebarOptions = (role: any) => {
        if (role.toLowerCase() === USER_ROLES.STUDENT) return studentSidebarMenuItems;
        if (role.toLowerCase() === USER_ROLES.PARENT_TEACHER) return parentTeacherSidebarMenuItems;
        if (role.toLowerCase() === USER_ROLES.TEACHER) return teacherDashboard;
        if (role.toLowerCase() === USER_ROLES.REGISTRAR) return registrarSidebarItems;
        if (role.toLowerCase() === USER_ROLES.PARENT) return parentDashboard;
        if (role.toLowerCase() === USER_ROLES.TENANT_ADMIN) return tenantAdminSetup
        else return this.state.tenantAdminSetup;
    }
    // Customizable Area End

    render() {

        // Customizable Area Start

        const { classes, sidebarWidth, isDarkMode, isSidebarOpen } = this.props;
        const roleFromStorage = JSON.parse(JSON.stringify(localStorage.getItem("role") || ""));
        const getFontWeightForSelected = (matchWindowPathString: any) => {
            const urlpath = new URL(document.URL); 
            const currentPath = urlpath.pathname.toLowerCase();
            if(typeof matchWindowPathString === "object") {
                if (matchWindowPathString?.includes(urlpath.pathname)) {
                    return 600; 
                }
                return 500;
            }else {
                const targetPath = `/${matchWindowPathString.toLowerCase()}`;       
                if (currentPath === targetPath) {
                    return 600; 
                }
                if (currentPath.startsWith(targetPath) && currentPath.length > targetPath.length) {
                    return 600; 
                }
                return 500;
            }
        }

        return (
            <Box width={sidebarWidth} flexBasis={sidebarWidth} className={`${classes.sidebarGrid} ${classes.bgColor} ${classes.highlightBgColor}`}>
                {this.getRoleSidebarOptions(roleFromStorage).map((menuItem: any) => {
                    return (  

                        <Box padding={"16px"} key={menuItem.id} className={classes.listitem} >
                            <Typography component={"span"} className={isSidebarOpen ? `${classes.textCommonColor} ${classes.mainMenuItemText}` : `${classes.textCommonColor} ${classes.mainMenuItemText} ${classes.mainMenuItemHidden}`} >{menuItem.mainMenuItemText}</Typography>
                            {menuItem.isSubMenuPresent &&
                                <>
                                    {menuItem.subMenuItems.map((subMenuItem: any) =>
                                        <Box onClick={() => {
                                            if(!subMenuItem.disabled){
                                                this.handleGotoScreen(subMenuItem.screenName)
                                            }
                                        }}
                                         className={classes.subMenuItemBox} mt="18px" mb="5px" display={"flex"} key={subMenuItem.id}
                                         style={subMenuItem.disabled ? { cursor: "not-allowed", opacity: 0.5 } : {}}
                                         >
                                            {subMenuItem.iconComponent(isDarkMode, subMenuItem.toMatch)}
                                            <Typography style={{
                                                color: handleSetSidebarIOptionColor(isDarkMode, subMenuItem.toMatch),
                                                fontWeight: getFontWeightForSelected(subMenuItem.toMatch)
                                            }} component={"p"} className={`${classes.listitem} ${classes.subMenuItem}`} >
                                                {subMenuItem.menuItemText}
                                            </Typography>
                                        </Box>
                                    )}
                                </>
                            }
                        </Box>
                    )
                })}
                <Box className={classes.watermarkBox}>
                    <Typography className={classes.watermark}>
                        Powered By
                        <br />
                        {this.state.companyName}
                    </Typography>
                </Box>
            </Box>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(WebSidebar);
// Customizable Area End