// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import TeacherCalendarController, {
  Props,
} from "./TeacherCalendarController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.css";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";
import DeleteIcon from "../../../components/src/SVGIcons/DeleteIcon.web";
import EditIcon from "../../../components/src/SVGIcons/EditIcon.web";
import DeleteEventPopUp from "./DeleteEventPopUp.web";
import EditEventPopUp from "./EditEventPopUp.web";
moment.locale("en-GB");
const localizer = momentLocalizer(moment);
// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
  });
const BtnTheme = createTheme({
  overrides: {
    MuiIconButton: {
      root: {
        padding: "0px",
        borderRadius: "8px",
        border: "1px solid #888888",
      },
    },
  },
});
const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "600px",
      },
      paperWidthSm: {
        maxWidth: "600px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class TeacherCalendar extends TeacherCalendarController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End
  renderCustomToolbar = (toolbar: any) => {
    const goToPrev = () => {
      const { view, date } = toolbar;
  
      if (view === "month") {
        date.setMonth(date.getMonth() - 1);
      } else if (view === "week") {
        date.setDate(date.getDate() - 7); // Move one week back
      } else if (view === "day") {
        date.setDate(date.getDate() - 1); // Move one day back
      }
  
      toolbar.onNavigate("prev");
    };
  
    const goToNext = () => {
      const { view, date } = toolbar;
  
      if (view === "month") {
        date.setMonth(date.getMonth() + 1);
      } else if (view === "week") {
        date.setDate(date.getDate() + 7); // Move one week forward
      } else if (view === "day") {
        date.setDate(date.getDate() + 1); // Move one day forward
      }
  
      toolbar.onNavigate("next");
    };

    return (
      <Box className="tool">
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{ gap: "20px" }}
        >
          <Typography className="rbc-toolbar-label" component={"span"}>
            {toolbar.label}
          </Typography>
          <ThemeProvider theme={BtnTheme}>
            <Box
              className="rbc-btn-group"
              style={{ display: "flex", gap: "20px" }}
            >
              <IconButton aria-label="close" onClick={goToPrev}>
                <ChevronLeftIcon fontSize="medium" />
              </IconButton>
              <IconButton aria-label="close" onClick={goToNext}>
                <NavigateNextIcon />
              </IconButton>
            </Box>
          </ThemeProvider>
        </Box>
        <Box className="rbc-btn-group" style={{ display: "flex", gap: "20px" }}>
          <Button
            data-test-id="tool"
            variant="outlined"
            color="secondary"
            onClick={() => toolbar.onView("day")}
            disabled={toolbar.view === "day"}
            style={{
              borderRadius: "10px",
              textTransform: "capitalize",
              borderColor: toolbar.view === "day" ? "#0D46BC" : "#888888",
              color: toolbar.view === "day" ? "#0D46BC" : "#888888",
            }}
          >
            Daily
          </Button>
          <Button
            variant="outlined"
            onClick={() => toolbar.onView("week")}
            disabled={toolbar.view === "week"}
            style={{
              borderRadius: "10px",
              textTransform: "capitalize",
              borderColor: toolbar.view === "week" ? "#0D46BC" : "#888888",
              color: toolbar.view === "week" ? "#0D46BC" : "#888888",
            }}
          >
            Weekly
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => toolbar.onView("month")}
            disabled={toolbar.view === "month"}
            style={{
              borderRadius: "10px",
              textTransform: "capitalize",
              borderColor: toolbar.view === "month" ? "#0D46BC" : "#888888",
              color: toolbar.view === "month" ? "#0D46BC" : "#888888",
            }}
          >
            Monthly
          </Button>
        </Box>
        <Divider
          style={{
            width: "105%",
            height: "1px",
            marginTop: "17px",
            marginLeft: "-25px",
          }}
        />
      </Box>
    );
  };
  eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
    const style = {
      backgroundColor: event.color,
      borderRadius: "5px",
      opacity: 0.8,
      color: event.textColor,
      border: "0px",
      display: "block",
      fontWeight: 700,
    };
    return {
      style,
    };
  };
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const isParent = this.state.isParent;

    return (
      <Box
        mt={4.5}
        style={{ width: "98%", backgroundColor: "white", borderRadius: "12px", overflow: "hidden" }}
        className={classes.customTableStyle}
      >
        <Calendar
          selectable
          localizer={localizer}
          style={{ height: "120%" }}
          defaultDate={new Date()}
          onSelectEvent={this.handleEventSelect}
          defaultView="month"
          events={this.props.events}
          startAccessor="start"
          endAccessor="end"
          eventPropGetter={this.eventStyleGetter}
          components={{
            toolbar: this.renderCustomToolbar,
          }}
          className="custom-rbc-toolbar"
          onNavigate={this.handleNavigate}
        />
        <ThemeProvider theme={theme}>
          <Dialog
            className={`${classes.highlightBgColor} ${classes.quizViewAnswerConfirmDialog}`}
            aria-labelledby="quiz-view-answer-confirm-dialog-title"
            style={{ width: "100%" }}
            open={this.state.selectEventPopUp}
            onClose={this.closeHandleEventSelect}
          >
            <Box
              padding={"21px 30px 30px 30px"}
              className={`${classes.bgColor}`}
            >
              <Grid direction="row" container>
                <Grid xs={12} item>
                  <Box width={"100%"} display={"flex"}>
                    <Box width={"60%"} display={"flex"}>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        style={{ gap: "10px" }}
                      >
                        <Box
                          width={"14px"}
                          height={"14px"}
                          style={{
                            backgroundColor: `${this.state.selectEventDetails.textColor}`,
                            borderRadius: "50%",
                          }}
                        ></Box>
                        <Typography
                          className={`${classes.fontText18} ${classes.fontBold600} ${classes.textPrimaryColor}`}
                          component={"span"}
                        >
                          {this.state.selectEventDetails.title}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      width={"40%"}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      { !isParent && 
                          <>
                            <IconButton
                              data-test-id="pop"
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={this.handleDeletePopUp}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              data-test-id="pop"
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={this.handleEditPopUp}
                            >
                              <EditIcon />
                            </IconButton>
                          </>
                      }
                      <IconButton
                        data-test-id="pop"
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={this.closeHandleEventSelect}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <DialogContent
                style={{
                  padding: "0px",
                  overflow: "hidden",
                }}
              >
                <Box px={"26"}>
                  <Box style={{ display: "flex", gap: "8px" }}>
                    <Typography
                      style={{ color: "#090909" }}
                      className={`${classes.fontText14} ${classes.textPrimaryColor}`}
                      component={"span"}
                    >
                      {this.state.selectEventDetails.date}
                    </Typography>
                    <Typography
                      style={{ color: "#090909" }}
                      className={`${classes.fontText14} ${classes.textPrimaryColor}`}
                      component={"span"}
                    >
                      {this.state.selectEventDetails.time}
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography
                      style={{ color: "#686868" }}
                      className={`${classes.fontText14}`}
                      component={"span"}
                      dangerouslySetInnerHTML={{ __html: this.state.selectEventDetails.desc }}
                    >
                    </Typography>
                  </Box>
                </Box>
              </DialogContent>
            </Box>
          </Dialog>
        </ThemeProvider>
        <DeleteEventPopUp
          open={this.state.openDeletePopUp}
          onCancel={this.handleCloseDeletePopUp}
          details={this.state.selectEventDetails}
          data-testId="delete-event"
          onConfirm={()=>this.handleDeleteEvent(this.state.selectEventDetails.id)}
        />
        <EditEventPopUp
          open={this.state.openEditPopUp}
          onCancel={this.handleCloseEditPopUp}
          onConfirm={this.handleUpdateEventApi}
          classes={this.props.classes}
          data-testId="update-event"
          details={this.state.selectEventDetails}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherCalendar);
// Customizable Area End
