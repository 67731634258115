// Customizable Area Start
import React from "react";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import TenantAdminSetupDashboardController, {
  Props,
} from "./TenantAdminSetupDashboardController.web"; 
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import SchoolYear from "../../cfcurriculmschoolyearcreation2/src/SchoolYear.web";
import TabPanel from "../../../components/src/TabPanel.web";
import TenantAdminSetupGrade from "../../cfgradingsystem2/src/TenantAdminSetupGrade.web";
import TenantAdminSetupSubjects from "../../cfcolorindicators5/src/TenantAdminSetupSubjects.web";
import TenantAdminSetupCurriculum from "../../cfcurriculmschoolyearcreation2/src/TenantAdminSetupCurriculum.web";
import TenantAdminSetupClassManagement from "../../cfcoursemanagement/src/TenantAdminSetupClassManagement.web";
import NewClassDialog from "../../cfcoursemanagement/src/NewClassDialog.web";
import CustomDragAndDropFileUpload from "../../../components/src/CustomDragAndDropFileUpload";
import CustomButton from "../../../components/src/CustomButton.web";
import TenantAdminSetupSchoolCalendar from "../../cfcurriculmschoolyearcreation2/src/TenantAdminSetupSchoolCalendar.web";
import TeacherAssignment from "../../assessmenttest/src/TeacherAssignment/TeacherAssignment.web";
import NewTeacherAssignmentDialog from "../../assessmenttest/src/TeacherAssignment/NewTeacherAssignmentDialog.web";
import  TenantAdminGradeBookList  from "./TenantAdminGradeBookList.web";
import { a11yProps } from "../../../components/src/CustomTabPanel.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    btnRoot: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    mainPagePadding: {
      paddingRight: "20px"
    },
    pageTitle: {
      fontSize: 34,
      fontWeight: 700,
    },
    tabLabel: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "23px",
      color: "#090909",
      textTransform: "capitalize",
      padding: theme.spacing(2.5, 0)
    },
    inputLabelText: {
      fontSize: 14,
      fontWeight: 400,
      color: "#888888",
    },
    breadCrumbRoot: {
      marginBottom: theme.spacing(2),
    },
    topBtnRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "25px",
    },
    topBtn: {
      borderRadius: "12px",
      backgroundColor: "#0D46BC",
      color: "#ffffff",
      padding: "15px",
      textTransform: "capitalize",
      fontSize: 18,
      fontWeight: 500,
    },
    appBar: {
      borderRadius: "12px 12px 0 0",
      backgroundColor: "#ffffff",
      boxShadow: "none",
      borderBottom: "2px solid #F1F1F1",
      '& .MuiSvgIcon-root': {
        color: "#0D46BC",
        fontSize: "xx-large",
      },
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid rgb(241, 241, 241)",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabButton: {
      fontSize: "16px",
      minWidth: "14.28%",
      textTransform: "capitalize",
      textAlign: "center",
    },
    tenantSetupDashboardMainContainer: {
      height: "132vh",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 8px 0px",
    },
    activeTab: {
      color: "#0D46BC",
      fontWeight: "bold",
      fontSize: 20,
      textTransform: "capitalize",
    },
    importCsvBtn: {
      marginRight: "20px",
      padding: "15px",
      color: "#0D46BC",
      border: "1px solid #0D46BC",
      borderRadius: "12px",
      textTransform: "capitalize",
      fontSize: 18,
      fontWeight: 600,
    },
  })
// Customizable Area End

// Customizable Area Start
export class TenantAdminSetupDashboard extends TenantAdminSetupDashboardController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const handleFileDrop = (file: File) => {
      // Handle the dropped file here
      console.warn('Dropped file:', file);
    };
    const { classes } = this.props;
    const {setupTabPermissions, setupTabDashboardListState,tabValue, newSchoolYearDialog, newGradeDialogOpen, newSubjectDialog, newCurriculumDialog, buttonConfig, newClassManagementDialog, csvDialog, newSchoolCalendarDailog, newAssignmentDialog ,showNewGrade , gradeBookPermission} = this.state;
    
    return (
      <Box className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`} height={"100%"}>
        <Box className={classes.breadCrumbRoot}>
          <Typography className={`${classes.textCommonColor} ${classes.fontText12}`} component={"span"}>
            {`Setup `}
          </Typography>
          <Typography className={`${classes.textButton} ${classes.fontBold600} ${classes.fontText12}`} component={"span"}>
            {`/ ${buttonConfig[tabValue].type}`}
          </Typography>
        </Box>
        <Box className={classes.topBtnRoot}>
          <Typography className={`${classes.textPrimaryColor} ${classes.fontText34} ${classes.fontBold600}`}>Setup</Typography>
          <Box>
            {(setupTabDashboardListState[tabValue].value === "class-management-tab" || setupTabDashboardListState[tabValue].value === "teacher-assignment-tab" )
            && (setupTabPermissions[buttonConfig[tabValue]?.writeId].write ) && (
              <CustomButton
                buttonId={'csv-dialog-btn'}
                buttonVariant="outlined"
                btnStyle={manageEventbtnStyle}
                isDisabled={false}
                type={"button"}
                handleButtonClick={() => this.setState({ csvDialog: true })}
                btnText="Import CSV"
              />
            )}
            {setupTabDashboardListState[tabValue].value == "school-calendar-tab" && 
            (setupTabPermissions[buttonConfig[tabValue]?.writeId].write) &&
            (
              <CustomButton
                btnText={"Manage Events"}
                buttonId={"manage-event-btn"}
                buttonVariant={"outlined"}
                isDisabled={false}
                type={"button"}
                handleButtonClick={this.handleNavigate}
                btnStyle={manageEventbtnStyle} />
            )}
            {(setupTabDashboardListState[tabValue].value == "grade-book-tab" && showNewGrade) ? 
            " ":
            (setupTabPermissions[buttonConfig[tabValue]?.writeId].write &&
              <>
                        {console.log("hello worlde",setupTabPermissions[buttonConfig[tabValue]?.writeId])}
            <CustomButton
              btnText={buttonConfig[tabValue].label}
              buttonId={buttonConfig[tabValue].id}
              buttonVariant={"contained"}
              isDisabled={false}
              type={"button"}
              handleButtonClick={buttonConfig[tabValue].handleClick}
              btnStyle={btnStyle} />
              </>
            )
            }
          </Box>
        </Box>
        <Box>
          <Box className={`${classes.bgColor} ${classes.tenantSetupDashboardMainContainer}`}>
              <Tabs
                id="tab-change-wrapper"
                variant="scrollable" 
                TabIndicatorProps={{
                  style: {
                    background: "none",
                  },
                }}
                scrollButtons="auto"
                className={`${classes.tabs} ${classes.customTabs}`}
                value={tabValue}
                onChange={this.handleChange}
              >
                 {this.state.setupTabDashboardListState.map((tabItem: any, index: number) => (
                    <Tab
                      key={tabItem.id}
                      data-testid={`tenant-setup-${tabItem.label}-tab`}
                      label={tabItem.label}
                      wrapped
                      {...a11yProps(index)}
                      className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
                      style={{ minWidth: `${100/setupTabDashboardListState.length}%`}}  
                    />
                  ))}
              </Tabs>
    {this.state.setupTabDashboardListState.map((tabItem: any, index: number) => {
      switch (tabItem.value) {
        case "school-year-tab":
          return (
            <TabPanel value={tabValue} index={index}>
              <SchoolYear navigation={this.props.navigation} openNewSchoolYearDialog={newSchoolYearDialog} handleCloseSchoolYearDialog={() => this.setState({ newSchoolYearDialog: false })} />
            </TabPanel> 
          )
        case "grade-tab":
          return (
            <TabPanel value={tabValue} index={index}>
              <TenantAdminSetupGrade navigation={this.props.navigation} openNewGradeDialog={newGradeDialogOpen} handleCloseNewGradePopup={() => this.setState({ newGradeDialogOpen: false })} />
            </TabPanel>
          )
        case "subject-tab":
          return(
            <TabPanel value={tabValue} index={index}>
              <TenantAdminSetupSubjects navigation={this.props.navigation} openNewSubjectDialog={newSubjectDialog} handleCloseNewSubjectPopup={() => this.setState({ newSubjectDialog: false })} />
            </TabPanel>
          )
        case "curriculum-tab":
          return (
                <TabPanel value={tabValue} index={index}>
                <TenantAdminSetupCurriculum navigation={this.props.navigation} openNewCurriculumDialog={newCurriculumDialog} handleCloseCurriculumDialog={() => this.setState({ newCurriculumDialog: false })} />
              </TabPanel>
          )
        case "class-management-tab":
          return(
            <TabPanel value={tabValue} index={index}>
              <TenantAdminSetupClassManagement  
              resetCSVTrigger={this.resetCSVTrigger}
              triggerCSVAPIs={this.state.triggerCSVAPIs} 
              navigation={this.props.navigation} 
              openNewClassManagementDialog={newClassManagementDialog} 
              handleCloseNewClassManagementDialog={() => this.setState({ newClassManagementDialog: false })}  />
            </TabPanel>
          )
        case "school-calendar-tab":
          return(
            <TabPanel value={tabValue} index={index}>
              <TenantAdminSetupSchoolCalendar navigation={this.props.navigation} openNewSchoolCalendarDialog={newSchoolCalendarDailog} handleCloseSchoolCalendarDialog={() => this.setState({ newSchoolCalendarDailog: false })} />
            </TabPanel>
          )
        case "teacher-assignment-tab":
          return (
            <TabPanel value={tabValue} index={index}>
              <TeacherAssignment 
                  resetCSVTrigger={this.resetCSVTrigger}
                  triggerCSVAPIs={this.state.triggerCSVAPIs} 
                  navigation={this.props.navigation} 
                  openNewTeacherAssignmentDialog={newAssignmentDialog} 
                  handleCloseTeacherAssignmentDialog={() => this.setState({ newAssignmentDialog: false })} 
              />
            </TabPanel>
          )
        case "grade-book-tab":
          return (
            <TabPanel value={tabValue} index={index}>
              <TenantAdminGradeBookList navigation={this.props.navigation} showNewGrade={showNewGrade} handleClose={this.handleCloseGradebook} handleEditGrade={this.handleEditGrade} gradeId={this.state.gradeId} permission={gradeBookPermission.write} />
            </TabPanel>
          )
        }})}
          </Box>
        </Box>
        <CustomDragAndDropFileUpload 
          open={csvDialog} 
          data-testid="import-csv-file"
          handleClose={this.handleCloseCsv} 
          onFileDrop={handleFileDrop} 
          onConfirm={this.handleImportCSVApi}
          downloadCSVFile={this.downloadCSVFile}
          message={setupTabDashboardListState[tabValue].value === "class-management-tab" ? "Class Mangement" : "Teacher Assignment"}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const btnStyle = {
  borderRadius: "12px",
  padding: "15px",
  textTransform: "capitalize",
  fontSize: 18,
  fontWeight: 600,
} as const;


const manageEventbtnStyle = {
  borderRadius: "12px",
  padding: "15px",
  textTransform: "capitalize",
  fontSize: 18,
  fontWeight: 600,
  marginRight: "15px",
  boxShadow: "none",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantAdminSetupDashboard);
// Customizable Area End


