// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  isPreviousModalClose?:boolean
  getDate?: any;
  dueDate?:any;
  selectedTopic?: any;
  selectedLesson?: any;
  selectedActivity?: any;
  details?:any;
  courseData?: any;
  selectType?: any;
  menuType?:any
  openDueDateSuccessModal?:any
  handleCloseSuccessDueDateChange?:any
  handleOpenSuccessDueDateChange?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedCourseTitle: string;
  openDueDateSuccessModal: boolean;
  selectCourseTitleValue: string;
  selectStudentValue: string;
  selectedValue: any;
  selectedValueLesson:any,
  dueDateChangeTopic: any,
  DuedateChangeLesson:any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ChangeDueDateJourneyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      loading: false,
      selectedCourseTitle: "",
      openDueDateSuccessModal: false,
      selectCourseTitleValue: "",
      selectStudentValue: "",
      selectedValue:'',
      selectedValueLesson:'Catch',
      dueDateChangeTopic: new Date(),
      DuedateChangeLesson: new Date(),
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    if(this.props.details?.dueDate) {
      this.setState({DuedateChangeLesson: new Date(this.props.details?.dueDate)})
    }
  }

  handleChange =async (event: any) => {
    this.setState({
      selectedValue: event.target.value
    })
  };
  handleChangeLesson =async (event: any) => {
    this.setState({
      selectedValueLesson: event.target.value
    })
  };
  handleDueDateSuccessModal = () => {
    this.props.onConfirm();
  };

  handleCloseChangeDueDateModal =()=>{
    this.props.handleCloseSuccessDueDateChange()
    this.props.onCancel()
  }

  dueDateChangeTopic = (date: any) => {
    this.setState({
      dueDateChangeTopic: date,
    });
    this.props.getDate(date);
  };
  dueDateChangeLesson = (date: any) => {
    this.setState({ 
      DuedateChangeLesson: date,
    });
    this.props.getDate(date);
  };
  // Customizable Area End
}
// Customizable Area End
