// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Modal, Tabs, Tab, CircularProgress } from "@material-ui/core";
import TenantAssignCatalogToTeacherModalController, {
  Props,
  assignCatalogTabsList,
} from "./TenantAssignCatalogToTeacherModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import {
  TabPanel,
  a11yProps,
} from "../../../components/src/CustomTabPanel.web";
import TenantCourseManagementMainTabComponentWeb from "./TenantCourseManagementMainTabComponent.web";
import CustomButtonWeb from "../../../components/src/CustomButton.web";
import TenantAssignCatalogToTeacherSuccessModalWeb from "./TenantAssignCatalogToTeacherSuccessModal.web";
// Customizable Area End

// Customizable Area Start
export const styles = (theme: Theme) =>
  createStyles({
    createCatalogModal: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    modalMainGrid: {
      borderRadius: "10px",
      minWidth: "70%",
      maxWidth: "70%",
      margin: "auto",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
      height: "90%",
      overflow: "auto",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100px",
        width: "auto",
        maxWidth: "100%",
      },
    },
    modalGridItemHeader: {
      padding: "10px 24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tabs: {
      "& div.MuiTabs-scroller": {
        borderBottom: "1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          justifyContent: "space-between",
        },
      },
    },
    tabButton: {
      fontSize: "16px",
      minWidth: "50%",
      textTransform: "capitalize",
      textAlign: "center",
    },
    borderRadius: {
      borderRadius: "12px",
    },
    tabPanelGrid: {
      paddingTop: "20px",
    },
    customLoader: {
      height: "140%",
      position: "absolute",
      display: "block",
      top: 0,
      left: 0,
      bottom: -30,
      right: 0,
      zIndex: 100,
      backgroundColor: "rgb(0, 0, 0, .4)",
    },
    circularContainer: {
      transform: "translate(-50%, -50%)",
      position: "absolute",
      top: "40%",
      left: "50%",
    },
  });

// Customizable Area End

export class TenantAssignCatalogToTeacherModal extends TenantAssignCatalogToTeacherModalController {
  static instance: any;
  displayApiErrorMsg: any;
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, classes } = this.props;
    const {
      tableHeaderColumns,
      assignCatalogTabIndex,
      inputConfigs,
      loading,
      availableTeachersListData,
      addedTeachers,
      availableTeacherPagination,
      addedTeacherPagination,
      openModels
    } = this.state; 
    return (
      <Modal
        open={open}
        className={`${classes.highlightBgColor} ${classes.createCatalogModal}`}
        onClose={this.props.onClose}
        aria-labelledby="assign-catalog-title"
        aria-describedby="assign-catalog-description"
        data-test-id="tenantModal"
      >
        <Box className={`${classes.bgColor} ${classes.modalMainGrid}`}>
          {loading && (
            <Box className={classes.customLoader}>
              <Box className={classes.circularContainer}>
                <CircularProgress data-test-id="circularProgress" className={`${classes.textButton}`} />
              </Box>
            </Box>
          )}
          <Box className={`${classes.modalGridItemHeader}`}>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}
              component={"span"}
            >
              Assign Catalog
            </Typography>
            <CloseIcon
              className={`${classes.textCommonColor} ${classes.cursorPointer}`}
              onClick={this.props.onClose}
              id="tenant-assign-catalog-close-modal-icon"
            />
          </Box>
          <Box>
            <Box padding={"10px 20px"}>
              <Box
                className={`${classes.secondaryBgColor} ${classes.borderRadius}`}
              >
                <Box>
                  <Tabs
                    value={assignCatalogTabIndex}
                    variant="scrollable"
                    TabIndicatorProps={{
                      style: {
                        background: "none",
                      },
                    }}
                    scrollButtons="auto"
                    data-testid="tenant-course-management-assign-catalog-to-teacher-tabs"
                    className={`${classes.tabs}`}
                    onChange={this.handleChangeTeacherTab}
                    aria-label="tenant-course-management-assign-catalog-to-teacher-tabs"
                  >
                    {assignCatalogTabsList.map(
                      (tabItem: any, index: number) => (
                        <Tab
                          key={tabItem.id}
                          data-testid={`tenant-course-management-assign-catalog-to-teacher-${tabItem.label}-tab`}
                          label={tabItem.label}
                          wrapped
                          className={`${classes.tabButton} ${classes.customTabButton} ${classes.textPrimaryColor}`}
                          {...a11yProps(index)}
                        />
                      )
                    )}
                  </Tabs>
                </Box>
                <Box className={`${classes.tabPanelGrid}`}>
                  <TabPanel value={assignCatalogTabIndex} index={0} data-test-id="tabPanel">
                 <TenantCourseManagementMainTabComponentWeb
                  data-test-id="TenantCourseManagementMainTabComponentWeb"
                      tenantCourseMngTabCategory="available_teachers"
                      handleSelectDropdownValue={
                        this.handleCatalogDropdownValue
                      }
                      inputDDConfigs={inputConfigs}
                      maxHeightForTableBox="90%"
                      renderDDMenuItem={this.renderCatalogItem}
                      tableBodyData={availableTeachersListData}
                      handleSearch={this.handleSearchTeacher}
                      tableHeaderColumns={tableHeaderColumns}
                      showMenuIconInTable={false}
                      pagination={availableTeacherPagination}
                      getPaginationNumber={(page: number) => this.handleAvailableTeacherPageChange(page)}
                    /> 
                  </TabPanel>
                  <TabPanel value={assignCatalogTabIndex} index={1} data-test-id="tabPanelOne">
                    <TenantCourseManagementMainTabComponentWeb
                    data-test-id="TenantCourseManagementMainTabComponentWebOne"
                      handleSelectDropdownValue={
                        this.handleCatalogDropdownValue
                      }
                      tenantCourseMngTabCategory="assigned_teachers"
                      handleSearch={this.handleSearchTeacher}
                      renderDDMenuItem={this.renderCatalogItem}
                      tableBodyData={addedTeachers}
                      inputDDConfigs={inputConfigs}
                      tableHeaderColumns={tableHeaderColumns}
                      showMenuIconInTable={false}
                      maxHeightForTableBox="90%"
                      pagination={addedTeacherPagination}
                      getPaginationNumber={(page: number) => this.handleAddedTeacherPageChange(page)}
                    />
                  </TabPanel> 
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"} padding={"10px 20px"} alignItems={"center"}>
            <CustomButtonWeb
            data-test-id="CustomButtonWebOne"
              buttonId={`tenant-assign-catalog-cancel`}
              btnStyle={{ ...saveBtn, marginRight: "15px" }}
              btnText="Cancel"
              buttonVariant={"outlined"}
              handleButtonClick={this.props.onClose}
              isDisabled={false}
              type={"button"}
            />
            { openModels && 
            <TenantAssignCatalogToTeacherSuccessModalWeb
            data-test-id="TenantAssignCatalogToTeacherSuccessModalWebTwo"
                open={openModels}
                onOkay={this.handleCloseAssignCatalogSuccessModal}
                onCancel={this.handleCloseAssignCatalogSuccessModal}
                dialogMessage={this.state.dialogMessage}
                dialogDeatils={this.state.dialogDeatils}
              />
            } 
            <CustomButtonWeb
            data-test-id="CustomButtonWebBTN"
              buttonId={`tenant-assign-catalog-save`}
              btnStyle={saveBtn}
              btnText="Save"
              buttonVariant={"contained"}
              handleButtonClick={this.handleSubmit}
              isDisabled={false || this.state.addedTeachers.length < 1}
              type={"button"}
            />
          </Box>
        </Box>
      </Modal>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const saveBtn = {
  width: "160px",
  padding: "10px 10px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "center",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantAssignCatalogToTeacherModal);
// Customizable Area End
