// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { displayApiErrorMsg, getMessageData, hideCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { GradeBook } from "./TenantAdminGradeBookListController.web";
const configJSON = require("./config.js");
const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface BtnConfig {
  id: string;
  label: string;
  isDisable:boolean,
  writeId:string;
  type: string;
  handleClick: () => void;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  tabValue: number;
  newSchoolYearDialog: boolean;
  newGradeDialogOpen: boolean;
  newSubjectDialog: boolean;
  newCurriculumDialog: boolean;
  newClassManagementDialog: boolean;
  newSchoolCalendarDailog: boolean;
  buttonConfig: Array<BtnConfig>;
  csvDialog: boolean;
  newAssignmentDialog: boolean;
  showNewGrade:boolean,
  gradeId:number,
  gradeBookCount:number;
  gradeBookPermission:{write:boolean,read:boolean},
  triggerCSVAPIs:boolean;
  setupTabDashboardListState:any;
  setupTabPermissions:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export const setupTabDashboardList = [
  {
    id: 1, 
    label: "School Year",
    value: "school-year-tab",
    isDisabled:false,
    keyWrite:"userManagementStudentParent",
  },
  {
    id: 2,
    label: "Grade",
    value: "grade-tab",
    isDisabled:false,
    isWrite:true,
    keyWrite:"userManagementTeacherParentTeacher",
  },
  { 
    id: 3,
    label: "Subjects",
    value: "subject-tab",
    isDisabled:false,
    isWrite:true,
    keyWrite:"userManagementOthers",
  },
  {
    id: 4,
    label: "Curriculum",
    value: "curriculum-tab",
    isDisabled:false,
    isWrite:true,
    keyWrite:"userManagementOthers",
  },
  {
    id:5,
    label:'Class Management',
    value:'class-management-tab',
    isDisable:false,
    isWrite:true,
    keyWrite:'useManagemewntOthers'
  },
  {
    id:6,
    label:'Teacher Assignment',
    value:'teacher-assignment-tab',
    isDisable:false,
    isWrite:true,
    keyWrite:'useManagemewntOthers'
  },
  {
    id:7,
    label:'Grade book',
    value:'grade-book-tab',
    isDisable:false,
    isWrite:true,
    keyWrite:'useManagemewntOthers'
  },
  {
    id:8,
    label:'School Calendar',
    value:'school-calendar-tab',
    isDisable:false,
    isWrite:true,
    keyWrite:'useManagemewntOthers'
  }

];





interface Permissions {
  gradebook:{can:{read:boolean,write:boolean}},
  school_year:{can:{read:boolean,write:boolean}}
}
export default class TenantAdminSetupDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = "";
  createGradeBookTenantApiCallId:string="";
  importCSVClassManagementId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      loading: false,
      tabValue: 0,
      csvDialog: false,
      newSchoolYearDialog: false,
      newGradeDialogOpen: false,
      newSubjectDialog: false,
      newCurriculumDialog: false,
      newClassManagementDialog: false,
      newSchoolCalendarDailog: false,
      newAssignmentDialog: false,
      showNewGrade:false,
      gradeId:-1,
      buttonConfig: [{
        id: "addNewSchoolYearBtn",
        label: "Add New School Year",
        isDisable:false,
        type: "School Year",
        writeId:"school_year",
        handleClick: () => {
          this.setState({
            newSchoolYearDialog: true
          })
        }
      },
      {
        id: "addNewGradeBtn",
        type: "Grade",
        isDisable:false,
        writeId:"grade",
        label: "Add New Grade",
        handleClick: () => {
          this.setState({
            newGradeDialogOpen: true
          })
        }
      },
      {
        id: "addNewSubjectBtn",
        type: "Subject",
        isDisable:false,
        writeId:"subject",
        label: "Add New Subject",
        handleClick: () => {
          this.setState({
            newSubjectDialog: true
          })
        }
      },
      {
        id: "addNewCurriculumBtn",
        type: "Curriculum",
        writeId:"curriculum",
        isDisable:false,
        label: "Add New Curriculum",
        handleClick: () => {
          this.setState({
            newCurriculumDialog: true
          })
        }
      },
      {
        id: "addNewClassManagementBtn",
        isDisable:false,
        type: "Class Management",
        writeId:"class_management",
        label: "Add New Class",
        handleClick: () => {
          this.setState({
            newClassManagementDialog: true
          })
        }
      },
      {
        id: "newAssignmentBtn",
        isDisable:false,
        type: "Teacher Assignment",
        writeId:"teacher_management",
        label: "Add New Assignment",
        handleClick: () => {
          this.setState({
            newAssignmentDialog: true
          })
        }
      },
      {
        id: "gradeBookBtn",
        isDisable:false,
        type: "Grade book",
        label: "Add New Grade Book",
        writeId:"gradebook",
        handleClick: () => {
          const request ={
            title:`Grade Book ${this.state.gradeBookCount}`,
            reporting_type: 0,
          }
          this.handleCreatePostGradeBookTenantApi(request)
        }
      },
      {
        id: "newSchoolCalendarBtn",
        isDisable:false,
        type: "School Calendar",
        writeId:"school_calender",
        label: "Add New School Calendar",
        handleClick: () => {
          this.setState({
            newSchoolCalendarDailog: true
          })
        }
      },
      ],
      gradeBookCount: 1,
      gradeBookPermission:{write:true,read:true},
      triggerCSVAPIs:false,
      setupTabDashboardListState:setupTabDashboardList,
      setupTabPermissions:{
        gradebook: {
          read:true,
          write:true
        },
        curriculum: {
          read:true,
          write:true
        },
        school_year:{
          read:true,
          write:true
        },
        school_calender:{
          read:true,
          write:true
        },
        class_management:{
          read:true,
          write:true
        },
        teacher_management:{
          read:true,
          write:true
        },
        subject:{
          read:true,
          write:true
        },
        grade:{
          read:true,
          write:true
        },
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  extractPermissions(parseData: any) {
    return  {
       gradebook: parseData?.gradebook?.can,
       curriculum: parseData?.curriculum?.can,
       school_year:parseData?.school_year?.can,
       school_calender:parseData?.school_calender?.can,
       class_management:parseData?.class_management?.can,
       teacher_management:parseData?.teacher_management?.can,
       subject:parseData?.subject?.can,
       grade:parseData?.subject?.can,
     };
   }
   checkTabPermission(tab: any, result: any) {
    const permissionMap: Record<string, string> = {
      "school-year-tab": "school_year",
      "grade-tab": "grade",
      "subject-tab": "subject",
      "curriculum-tab": "curriculum",
      "class-management-tab": "class_management",
      "teacher-assignment-tab": "teacher_management",
      "grade-book-tab": "gradebook",
      "school-calendar-tab": "school_calender",
    };
  
    const permissionKey = permissionMap[tab.value];
    if (permissionKey && !result[permissionKey]?.read) {
      return { ...tab, isDisabled: true };
    }
  
    return tab;
   }
   checkButtonPermission(tab: any, result: any) {
    const permissionMap: Record<string, string> = {
      addNewSchoolYearBtn: "school_year",
      addNewGradeBtn: "grade",
      addNewSubjectBtn: "subject",
      addNewCurriculumBtn: "curriculum",
      addNewClassManagementBtn: "class_management",
      newAssignmentBtn: "teacher_management",
      gradeBookBtn: "gradebook",
      newSchoolCalendarBtn: "school_calender",
    };
    const permissionKey = permissionMap[tab.id];
    if (permissionKey && !result[permissionKey]?.read) {
      return { ...tab, isDisabled: true };
    }
    return tab;
  }
   filterTabsByPermissions(result: any) {
     return this.state.setupTabDashboardListState.map((tab: any) => this.checkTabPermission(tab, result))
     .filter((tabItem: any) => !tabItem.isDisabled);
   }
   filterButtonConfigs(result: any) {
    return this.state.buttonConfig.map((tab: any) => this.checkButtonPermission(tab, result))
    .filter((tabItem: any) => !tabItem.isDisabled);
  }
   async handleGetRoleAndPermissionForUserManagement(){
     const getSetupPermission = await getStorageData("roleandpermission")
     if(getSetupPermission){  
       try {
         const parseData = JSON.parse(getSetupPermission);
         if(parseData){
         const result = this.extractPermissions(parseData);
         const updatedSetupTabsList:any = this.filterTabsByPermissions(result);
         const updatedButtonConfig = this.filterButtonConfigs(result)
         this.setState({
          setupTabDashboardListState:updatedSetupTabsList,
          setupTabPermissions:result,
          buttonConfig:updatedButtonConfig
         })
       }
       } catch (error) {
         console.error("Failed to parse setup permission. It might not be valid JSON.", error);
       }
     }
   }
  resetCSVTrigger =()=>{
    this.setState({triggerCSVAPIs:false})
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // const historyLocationState = this.props.navigation?.getHistoryState();
    // if(historyLocationState){
    //   this.setState({tabValue:historyLocationState.tabOpen,showNewGrade:true,gradeId:historyLocationState.gradeBookId},()=>{
    //     this.handleEditGrade(this.state.gradeId)
    //   })
    // }
      // console.log("opppppp",historyLocationState)
      this.handleGetRoleAndPermissionForUserManagement()
    const savedCount = await getStorageData('gradeBookCount');
    const getPermissions = await getStorageData('roleandpermission', true);
    this.handlePermissons(getPermissions)
    if(savedCount){
      const newCount =savedCount+1
      this.setState({ gradeBookCount: newCount },async () => {
        await setStorageData('gradeBookCount',newCount );
      });
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleCloseGradebook=()=>{
    this.setState({
      showNewGrade:false
    })
  }
  handleEditGrade = (gradeId:number) => {
    this.setState({
      showNewGrade: true,
      gradeId: gradeId, 
    });
  };
  handleNavigate = ()=>{
   this.props.navigation?.navigate("ManageEvents")
  }

  handleChange = (event: any, newValue: number) => {
    this.setState({
      tabValue: newValue,
    });
  };

  handleCloseCsv = () => {
    this.setState({ csvDialog: false })
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(
        message
      );
 
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.createGradeBookTenantApiCallId) {
          this.handlePostGradeBookTenantResponse(responseJson);
        }else if(apiRequestCallId === this.importCSVClassManagementId){
          this.handleImportCSVResponse(responseJson)
        }
      }
    }
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      this.sessionDataFetch(sessionData)
    }
  }
  sessionDataFetch= (sessionData:any)=>{
    if (sessionData) {
    const {tabOpen,gradeBookId,tabValue,} = sessionData
    let tabOpenValue = 0
    if(tabValue){
      const index = this.state.setupTabDashboardListState.findIndex((item: { value: string; }) => item.value === tabValue);
      if(index){
        tabOpenValue=index
      }
    }
    else{
      tabOpenValue =tabOpen
    }
    this.setState({tabValue:tabOpenValue,showNewGrade:true,gradeId:gradeBookId},()=>{
      this.handleEditGrade(this.state.gradeId)
    })
    }
  }

  handleCreatePostGradeBookTenantApi =async (requestBody:{title:string}) =>{
    const requestUrl = configJSON.gradeBookTenantEndpoint;
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createGradeBookTenantApiCallId= requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handlePostGradeBookTenantResponse = async(responseJson: {
    data:GradeBook;
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
   
      this.setState({
        showNewGrade: true,
        gradeId:responseJson.data.attributes.id
      })
      toast.success("Grade Book created successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
      hideCustomLoader();
  }
  handleImportCSVApi =async (requestBody:any) =>{
    const {setupTabDashboardListState,tabValue} = this.state
    const requestUrl = setupTabDashboardListState[tabValue].value === "class-management-tab"  ? configJSON.importCSVClassManagement : configJSON.importCSVTeacherAssignment;
    const token = await getStorageData("token");
      const header = {
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.importCSVClassManagementId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        requestBody
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleImportCSVResponse = async(responseJson: {
    data:any;
    errors: {message:Array<string>;}})=>{
      if (responseJson?.data?.success?.length > 0) {
        this.handleCloseCsv();
        this.setState({triggerCSVAPIs:true})
        toast.success(`${responseJson?.data?.success[0]?.message}`);
      }else if(responseJson?.data?.errors?.length > 0){
        displayApiErrorMsg(responseJson?.data?.errors[0]?.message, this.props.navigation);
      }
        else {
        displayApiErrorMsg(responseJson?.errors?.message[0], this.props.navigation);
      }
      hideCustomLoader();
  }
  downloadCSVFile =async () => {
    const {setupTabDashboardListState,tabValue} = this.state
    const token = await getStorageData("token");
    let requestType = setupTabDashboardListState[tabValue].value === "class-management-tab" ? configJSON.downloadCSVClassMangement:configJSON.downloadCSVTeacherAssignment
    let requestUrl = `${requestType}?token=${token}`
    window.open(`${baseURL}/${requestUrl}`, '_blank');
  };

  handlePermissons = (permissions:Permissions) => {
    if(permissions){
      if (permissions.gradebook) {
        this.setState({gradeBookPermission:{write:permissions.gradebook.can.write,
          read:permissions.gradebook.can.read
        }})
      }
    }

  }

  // Customizable Area End
}
// Customizable Area End