// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, getMessageData } from "../../../components/src/CommonHelper.web";
import { toast } from "react-toastify";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

interface IProps {
  id: any;
  completionProgress: any;
  title: string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  index?: any;
  navigation?: any;
  id?: string;
  showInfo: boolean;
  classes?: any;
  handleSelectedItem: any;
  detail: IProps;
  selectedItemId?: any;
  key: any;
  menuType?: any;
  isFromTenant?: any;
  isStudent?: boolean;
  getMenuItem: any;
  handleInfoDialog?: any;
  courseData?:any;
  topicId?:string;
  isParent?:any;
  callActivityApi?:()=>void;
  callLessonsApi?:(search:any)=>void;
  isActivityWrite?:boolean
  isJourneyCourseManagment?:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  menuAnchorEl: any;
  loadings: boolean;
  openChangeDueDateModal: boolean;
  openChangeDueDatelessonModal: boolean;
  openSkippedModal: boolean;
  openMarkCompletedModal: boolean;
  id:string;
  dueDate:Date|string;
  markAsCompleteSuccessModal:boolean;
  openDueDateSuccessModal:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentCourseJourneyBoxController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetStudentCoursesJourneyDetails: string = "";
  dueDateUpdateApiCallID:string = ""
  markAsCompleteLessonsApiCallID:string = ""
  dueDateUpdateApiCallID2:string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      menuAnchorEl: null,
      loadings: false,
      openChangeDueDateModal: false,
      openChangeDueDatelessonModal: false,
      openSkippedModal: false,
      openMarkCompletedModal: false,
      dueDate:'',
      id:'',
      markAsCompleteSuccessModal:false,
      openDueDateSuccessModal:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, responseJson } =
        getMessageData(message);
      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.markAsCompleteLessonsApiCallID) {
          this.handleGetMarkAsCompleteResponse(responseJson);
        }
        if(apiRequestCallId === this.dueDateUpdateApiCallID) {
          this.handleGetDueDataChangeResponse(responseJson);
        }
        if ( apiRequestCallId === this.dueDateUpdateApiCallID2) {
          this.handleGetDueDataChangeResponse(responseJson);
        }
      }
      // Customizable Area Start

      // Customizable Area End
    }
  }

  // Customizable Area End
  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.handleResetInitialsValue();
    // Customizable Area End
  }

  // Customizable Area Start
  handleResetInitialsValue = () => {
    this.setState({
      menuAnchorEl: null,
      loadings: false,
      openChangeDueDateModal: false,
      openChangeDueDatelessonModal: false,
      openSkippedModal: false,
      openMarkCompletedModal: false,
      id:''
    })
  }

  handleEditActionClick = (e: any, id: any) => {
    this.setState({ menuAnchorEl: e?.currentTarget });
  };
  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null });
  };

  handleMenuModalOpen = () => {
    if (this.props.menuType === "Topic") {
      this.setState({
        openChangeDueDateModal: true,
      });
    } else if (this.props.menuType === "lessons") {
      this.setState({
        openChangeDueDatelessonModal: true,
      });
    }
  };

  handleCloseChangeDueDateModal = () => {
    if (this.props.menuType === "Topic" || this.props.menuType === "lessons") {
      this.setState({
        openChangeDueDatelessonModal: false,
        menuAnchorEl: null
      });
    }
  };

  handleSkippedModalOpen = () => {
    if (this.props.menuType === "Topic" || this.props.menuType === "lessons") {
      this.setState({
        openSkippedModal: true,
        menuAnchorEl: null
      })
    }
  }

  handleCloseSkippedModal = () => {
    if (this.props.menuType === "Topic" || this.props.menuType === "lessons") {
      this.setState({
        openSkippedModal: false,
      })
    }
  }
  handleCoursesMarkCompleteModal = () => {
    if (this.props.menuType === "Topic" || this.props.menuType === "lessons") {
      this.setState({
        openMarkCompletedModal: true,
        menuAnchorEl: null
      })
    }
  }
  handleCloseMarkCompletedModal = () => {
    this.setState({
      openMarkCompletedModal: false,
      markAsCompleteSuccessModal:false
    })
    this.props.callActivityApi && this.props.callActivityApi()
    this.props.callLessonsApi && this.props.callLessonsApi({})
  }

  handleEditMenuOption = () => {
    const menuData = {
      menu: "Edit",
      itemId: this.props.detail.id,
      itemName: this.props.detail.title,
      itemType: this.props.menuType,
    }
    if (menuData) {
      this.props.getMenuItem(menuData);
    }
    this.handleMenuClose();
  }

  handleDeleteMenuOption = () => {
    const menuData = {
      menu: "Delete",
      itemId: this.props.detail.id,
      itemName: this.props.detail.title,
      itemType: this.props.menuType,
    }
    if (menuData) {
      this.props.getMenuItem(menuData);
    }
    this.handleMenuClose();
  }

  handleInfoIconClick = (event: any) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
      const { handleInfoDialog, detail } = this.props;
      handleInfoDialog(detail);
    }
  }
  handleCloseSuccessDueDateChange=()=>{
    this.setState({ openDueDateSuccessModal: false });
  }
  handleOpenSuccessDueDateChange=()=>{
    this.setState({ openDueDateSuccessModal: true });
  }

  handleDueDateChange = async () => {
    const token = await getStorageData("token");
    const header = { token };
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dueDateUpdateApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.lessonsDueDateEnfPoisn}?id=${this.state.id}&date=${this.state.dueDate}&topic_id=${this.props.topicId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleOptionClick = (type: string, id: string) => {
    if(type === "Change Due Date") {this.setState({openChangeDueDatelessonModal: true});
    }else if (type === "Mark As Complete") {this.setState({openMarkCompletedModal: true});
    }
    this.setState({id});
  }

  dueDateChange = (date: any) => {
    const dateFormat = new Date(date)
    
const year = dateFormat.getFullYear().toString().slice(-2); 
const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0'); 
const day = dateFormat.getDate().toString().padStart(2, '0')

const formattedDate = `${year}-${month}-${day}`;
    this.setState({
      dueDate: formattedDate,
    });
  };

  handleConfirmClick = () => {
    const {menuType}= this.props
    this.setState({openChangeDueDatelessonModal: false});
     if (menuType === "lessons") {
      this.handleDueDateChange();
     }else{
      this.handleDueDateChangeTopic()
     }
   
  }

  handleMarkAsCompleteConfirm = async () => {
    const token = await getStorageData("token");
    const header = {
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsCompleteLessonsApiCallID = requestMessage.messageId;
   if (this.props.menuType === "lessons") {
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markAsCompleteLessonsEndPoint}?id=${this.state.id}&topic_id=${this.props.topicId}`
    );
   }else{
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markAsCompleteLessonsEndPoint}?id=${this.state.id}&course_id=${this.props.courseData.id}`
    );
   }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleGetDueDataChangeResponse = (response: any) => {
    if(response.message){
      toast.success(response.message)
      this.setState({openDueDateSuccessModal:true})
    } else {
      displayApiErrorMsg(response?.error[0], this.props.navigation);
    }
  }
  handleGetMarkAsCompleteResponse = (response: any) => {
    if(response.message === "Only student will done this.") {
      toast.error(response.message)
      this.setState({markAsCompleteSuccessModal: false})
    }else{ 
      toast.success(response.message)
      this.setState({markAsCompleteSuccessModal: true})
    }
  }

  handleDueDateChangeTopic = async () => {
    const token = await getStorageData("token");
    const header = { token };
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dueDateUpdateApiCallID2 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.topicDueDateEnfPoisn}?id=${this.state.id}&date=${this.state.dueDate}&course_id=${this.props.courseData.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  

  // Customizable Area End
}
// Customizable Area End
