// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import {
  TOASTER_NOTIFICATION_MSG,
  USER_ROLES,
} from "../../../components/src/CommonType.web";
import { toast } from "react-toastify";
import { displayApiErrorMsg, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";

export const configJSON = require("./config");
interface ErrorDetail {
  failed_login?: string;
  account?: string;
}
interface MetaData {
  token: string;
  refresh_token: string;
  id: number;
  content_type: string;
  filename: string;
  role: string;
  url: string;
  user_name: string;
  errors?: ErrorDetail[];
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  username: string;
  password: string;
  rememberMe: boolean;
  emailPassWordValidateError: boolean;
  emailPasswordValidateErrorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostLoginDetailsId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      username: "",
      password: "",
      rememberMe: false,
      emailPassWordValidateError: false,
      emailPasswordValidateErrorMsg: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    await removeStorageData("token");
    await removeStorageData("role");
    await removeStorageData("userEmail");
    // Customizable Area End
  }
  // Customizable Area Start

  handleGoToForgotPassword = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  handleValidateLogin = () => {
    const { username, password } = this.state;
    let isValidated = true;
    if (!username?.trim() || !password?.trim()) {
      isValidated = false;
      toast.info(TOASTER_NOTIFICATION_MSG.EMPTY_LOGIN_FIELDS);
    }
    return isValidated;
  };

  handleLoginButton = () => {
  
    if (this.handleValidateLogin()) {
      showCustomLoader();
      const { username, password } = this.state;

      const header = {
        "Content-Type": configJSON.loginApiContentType,
      };

      const httpBody = {
        data: {
          type: "email_account",
          attributes: {
            user_name: username,
            password: password,
          },
        },
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiPostLoginDetailsId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginWebAPiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  handleDashboardRedirection = (role: string) => {
    const { navigation } = this.props;
    if (role.toLowerCase() === USER_ROLES.REGISTRAR) {
      navigation.navigate("RegistrarStudentsList");
    } else if (role.toLowerCase() === USER_ROLES.STUDENT) {
      navigation.navigate("StudentDashboard");
    } else if (role.toLowerCase() === USER_ROLES.TEACHER) {
      navigation.navigate("TeacherDashboard");
    } else if (role.toLowerCase() === USER_ROLES.PARENT_TEACHER) {
      navigation.navigate("ParentTeacherDashboard");
    } else if (role.toLowerCase() === USER_ROLES.PARENT) {
      navigation.navigate("ParentDashboard");
    } else {
      navigation.navigate("TenantDashboardMain");
    }
  };
  handleResponseErrors(responseJson:MetaData) {
    if (!responseJson.errors || responseJson.errors.length === 0) {
      return displayApiErrorMsg(responseJson.errors, this.props.navigation);
    }
  
    const errorObj = responseJson.errors[0];
    let errorMessage = null;
  
    if (errorObj.failed_login) {
      errorMessage = errorObj.failed_login;
    } else if (errorObj.account) {
      errorMessage = errorObj.account;
    }
  
    if (errorMessage) {
      return toast.error(errorMessage);
    } else {
      return displayApiErrorMsg(responseJson.errors, this.props.navigation);
    }
  }

  async receive(from: string, message: Message) {
    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      // Customizable Area Start
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiPostLoginDetailsId) {
          if (responseJson?.meta?.token) {
            await setStorageData("user_id", responseJson?.meta?.id);
            await setStorageData("user_name", responseJson?.meta?.user_name);
            await setStorageData("role", responseJson?.meta?.role);
            await setStorageData("token", responseJson?.meta?.token);
            await setStorageData("user_profile_img", responseJson?.meta?.url);
            await setStorageData("dark_mode", responseJson?.meta?.appeareance_and_theme?.data?.attributes?.dark_mode);
            await setStorageData("logo_url", responseJson?.meta?.appeareance_and_theme?.data?.attributes?.dark_mode);
            await setStorageData("appearanceTheme",JSON.stringify(responseJson.meta?.appeareance_and_theme?.data.attributes	))
            await setStorageData("roleandpermission",JSON.stringify(responseJson.meta?.roles_and_permission))
            window.dispatchEvent(new Event('roleandpermission'))
            window.dispatchEvent(new Event('appearanceTheme'));
            this.handleDashboardRedirection(
              responseJson.meta?.role || "Student"
            );
            toast.success(TOASTER_NOTIFICATION_MSG.LOGIN_SUCCESS);
          } else {
            //Check Error Response
            this.handleResponseErrors(responseJson);
          }
          hideCustomLoader();
          this.parseApiCatchErrorResponse(errorResponse);
        }
      }

      // Customizable Area End
    }
  }

  // Customizable Area End
}
// Customizable Area End
