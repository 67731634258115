// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  onCancel: () => void;
  onConfirm: () => void;  
  selectedRowid?: any;
  open: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  paginationData: any;
  searchTitleText: string;
  selectedRowId: number;
  selectedBrowseTitleDDValue: any;
  selectedBrowseSubjectDDValue: any;
  titleBrowseTitleComponentValue: Array<any>;
  selectedBrowseGradeDDValue: any;  
  BrowseSubjectComponentValue: Array<any>;
  BrowseGradeComponentValue: Array<any>;
  coursesData:Array<any>;
  paginationLimit: number;
  catalogPaginationData: any;  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherBrowseCourseCatModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCatalogList: string = "";
  apiGetCatalogFilterListId:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      paginationData: {},
      searchTitleText: "",
      coursesData:[],
      paginationLimit: 10,
      catalogPaginationData: {},
      selectedRowId: -1,
      selectedBrowseTitleDDValue: "",
      selectedBrowseSubjectDDValue:"",
      selectedBrowseGradeDDValue: "",
      titleBrowseTitleComponentValue: [],      
      BrowseSubjectComponentValue:[],  
      BrowseGradeComponentValue:[],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount(); 
      this.handleGetCatalogFilterItem()
      this.handleGetCatalogList({},{});      
    

  }



  async receive(_from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson ,errorResponse} = getMessageData(message);

      if (apiRequestCallId === this.apiGetCatalogList) {
        this.handleCatalogListResponse(responseJson, errorResponse);
      }
      if(apiRequestCallId === this.apiGetCatalogFilterListId){
        this.handleGetCatalogFilterItemResponse(responseJson)
      }
      
    }
  }

  handleCatalogListResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson?.data) {
      const response = responseJson?.data;
      const catalogList = response?.map(
        (item: any, index: number) => {
          return this.handleSetCatalogItem(item);
        }
      );
      const titledropdowndata = response?.map(
        (item:any,index:number) => {
          return {
            id: item.id,
            value: item.id,
            label: item?.attributes?.course_title,
          }
        }
      )
      const subjectropdowndata = response?.map(
        (item:any,index:number) => {
          return {
            id: item.id,
            value: item.id,
            label: item?.attributes?.subject?.name,
          }
        }
      )
      const Gradedropdowndata = response?.map(
        (item:any,index:number) => {
          return {
            id: item.id,
            value: item.id,
            label: item?.attributes?.grade?.name,
          }
        }
      )


      this.setState({    
        coursesData: catalogList || [],
        catalogPaginationData: responseJson?.meta?.pagination,
        paginationData:responseJson?.meta?.pagination,
        // titleBrowseTitleComponentValue:titledropdowndata,
        // BrowseSubjectComponentValue:subjectropdowndata,
        // BrowseGradeComponentValue:Gradedropdowndata,
      }, () => {   
             console.log("hello world",this.state.coursesData)
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };
  handleGetCatalogFilterItemResponse(responseJson:any){
    if (responseJson?.data) {
      const response = responseJson?.data;
      const titledropdowndata = response?.map(
        (item:any,index:number) => {
          return {
            id: item.id,
            value: item?.attributes?.course_title,
            label: item?.attributes?.course_title,
          }
        }
      )?.filter((item: { value: any; }, index: any, self: any[]) => 
        index === self.findIndex((t: { value: any; }) => t.value === item.value)
      );
      const subjectropdowndata = response?.map(
        (item:any,index:number) => {
          return {
            id: item?.attributes?.subject?.id,
            value: item?.attributes?.subject?.id,
            label: item?.attributes?.subject?.name,
          }
        }
      )?.filter((item: { id: any; }, index: any, self: any[]) => 
        index === self.findIndex((t: { id: any; }) => t.id === item.id)
      );
      const Gradedropdowndata = response?.map(
        (item:any,index:number) => {
          return {
            id: item?.attributes?.grade?.id,
            value: item?.attributes?.grade?.id,
            label: item?.attributes?.grade?.name,
          }
        }
      )?.filter((item: { id: any; }, index: any, self: any[]) => 
        index === self.findIndex((t: { id: any; }) => t.id === item.id)
      );


      this.setState({    
        titleBrowseTitleComponentValue:titledropdowndata,
        BrowseSubjectComponentValue:subjectropdowndata,
        BrowseGradeComponentValue:Gradedropdowndata,
      }, () => {   
             
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
  }


  handleSetCatalogItem = (item: any) => {
    const coursesData = item.attributes;
    const corseData = item
    return {    

    id: item?.id,
    Title_Browse: {
      name: coursesData?.course_title,
      icon: "",
    },
    Subject_Browse: coursesData?.subject?.name,
    Grade_Browse: coursesData?.grade?.name,
    Type_Browse: corseData?.type,
    CreatedOn_Browse: moment(coursesData?.created_at).format('MMMM Do, YYYY'),
    LastModifiedOn_Browse: moment(coursesData?.updated_at).format('MMMM Do, YYYY'),
    }

  }

  handleGetCatalogFilterItem = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
     const  apiConfig = configJSON.getCatalogCourses 
    const header = {
      token,
    };
    const baseUrl = apiConfig + `?per_page=500`;
    let requestUrl = baseUrl;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      
    );

    this.apiGetCatalogFilterListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
 

  handleGetCatalogList = async (searchQury:any,pagination: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
     const  apiConfig = configJSON.getCatalogCourses 
    const header = {
      token,
    };

    const baseUrl = apiConfig + `?per_page=${pagination.limit || this.state.paginationLimit}`;
    let requestUrl = baseUrl;

    if (searchQury.course) {
      requestUrl = requestUrl + `&course_title=${searchQury.course}`;
    };
    if (searchQury.grade) {
      requestUrl = requestUrl + `&grade_id=${searchQury.grade}`;
    };
    if (searchQury.subject) {
      requestUrl = requestUrl + `&subject_id=${searchQury.subject}`;
    };
    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      
    );

    this.apiGetCatalogList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

 
  renderBrowseTitleDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Title";
    }
    const { titleBrowseTitleComponentValue } = this.state;
    const selectedGradingComponents = titleBrowseTitleComponentValue.find(
      (grade: any) => grade?.value === selected
    );
    return selectedGradingComponents?.label;
  };

  renderBrowseSubjectDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Subject";
    }
    const { BrowseSubjectComponentValue } = this.state;
    const selectedGradingComponents = BrowseSubjectComponentValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  renderBrowseGradeDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Grade";
    }
    const { BrowseGradeComponentValue } = this.state;
    const selectedGradingComponents = BrowseGradeComponentValue.find(
      (grade: any) => grade?.id === selected
    );
    return selectedGradingComponents?.label;
  };

  handleBrowseDropdownChange = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
  }>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValue: any = value;
    if (fieldValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValue,
        };
      });
    }
  };

  handleSearch = () => {
    const { selectedBrowseTitleDDValue,selectedBrowseSubjectDDValue ,selectedBrowseGradeDDValue} = this.state;
    this.handleGetCatalogList({course:selectedBrowseTitleDDValue,subject:selectedBrowseSubjectDDValue,grade:selectedBrowseGradeDDValue},{});
    console.log("Search");
  }

  handleSelectedRow = (tableRowId: number) => {    
    if (tableRowId) {
      this.setState({ selectedRowId: tableRowId });      
    } else {
      this.setState({ selectedRowId: -1 });
    }
  }  
  handlePagination = (event: any, page: number) => {
    const { selectedBrowseTitleDDValue,selectedBrowseSubjectDDValue ,selectedBrowseGradeDDValue} = this.state;
    this.handleGetCatalogList({course:selectedBrowseTitleDDValue,subject:selectedBrowseSubjectDDValue,grade:selectedBrowseGradeDDValue},{page:page,limit: this.state.paginationLimit})    
  }
  // Customizable Area End
}
// Customizable Area End