import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { dummyIcon, imgPasswordInVisible, imgPasswordVisible} from "./assets";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  notificationData:any[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Emailnotifications2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiNotificationListId:string="";
  apiNotificationDeleteId:string="";
  intervalId: any = null; 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      notificationData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(
        message
      );
 
      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.apiNotificationListId:
              this.handleGetNotificationListResponse(responseJson)
              break;
          case this.apiNotificationDeleteId:
              this.handleDeleteNotificationResponse(responseJson);
              break;
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.handleGetNotificationListApi()
    
    this.intervalId = setInterval(() => {
      this.handleGetNotificationListApi(true);
    }, 10000);
  }
  async componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  handleGetNotificationListApi = async (doNotLoader?:boolean) => {
    if(!doNotLoader){
      showCustomLoader()
    }
    const requestUrl = `${configJSON.notificationEndpoint}`;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 

    this.apiNotificationListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetNotificationListResponse = async(responseJson: {
    data: any[]
    errors: Array<{message:string}>;})=>{

    if (responseJson?.data) {  
      const notificationList = responseJson?.data.map((item, index) => ({
        id: item.id,
        attributes: {
          is_read: item.attributes.is_read,
          icon: item?.attributes?.icon_url?.icon || dummyIcon,
          headings: item.attributes.headings,
          created_at: item.attributes.created_at,
          contents: item.attributes.contents.replace(/\n/g, '<br/>'), 
        },
      }));
      const sortedNotifications = [...notificationList];  

      sortedNotifications.sort((a, b) => {
        const dateA = new Date(a.attributes.created_at).getTime();
        const dateB = new Date(b.attributes.created_at).getTime(); 
        
        return dateB - dateA;  
      });
    this.setState({notificationData:sortedNotifications})
    }else if(responseJson?.errors[0]?.message === "No notification found.") {
      this.setState({notificationData:[]})
    }
    else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  }
  handleDeleteNotification=(valueId:number)=>{
    this.handleDeleteNotificationApi(valueId)
  }
  handleDeleteNotificationApi  = async (notificationId: number) => {
    const requestUrl = `${configJSON.notificationEndpoint}/${notificationId}`;
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiNotificationDeleteId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleDeleteNotificationResponse = async (responseJson: { message: string; errors?: Array<{}> }) => {
    if (responseJson?.message) {
      await this.handleGetNotificationListApi();
      toast.success("Notification Deleted successfully!");
    } else {
      displayApiErrorMsg(responseJson.errors || [], this.props.navigation);
    }
    hideCustomLoader();
  };

  // Customizable Area End
}
