// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");
export interface IDropdownItems {
  id: any;
  label: string;
  value: any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  openCourseJourney: (rowData: any) => void;
  classId: string;
  classesList?: any;
  currentRole?:string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedGradeValue: string;
  selectedTitleValue: string;
  selectedStatusValue: string;
  selectedLessonValue: string;
  selectedUtilizationValue: string;
  coursesStatusComponentValue: Array<any>;
  coursesTitleComponentValues: Array<any>;
  paginationLimit: number;
  coursesTableData: Array<any>;
  pagination: any;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherCoursesTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTeacherCourses: string = "";
  apiGetCourseDropdownData: string = "";
  apiGetParentStudentCourses: string ="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      selectedGradeValue: "",
      selectedTitleValue: "",
      selectedStatusValue: "",
      selectedUtilizationValue: "",
      selectedLessonValue: "",
      paginationLimit: 10,
      coursesTableData: [],
      pagination: {},
      loading: false,
      coursesStatusComponentValue: [
        {
          id: 1,
          label: "Scheduled",
          value: "Scheduled",
        },
        {
          id: 2,
          label: "Late",
          value: "Late",
        },
        {
          id: 3,
          label: "Completed",
          value: "Completed",
        },
      ],
      coursesTitleComponentValues: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } =
        getMessageData(message);

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetTeacherCourses) {
          this.handleGetTeacherCourseResponse(
            responseJson,
            errorResponse
          );
        }
        if (apiRequestCallId === this.apiGetParentStudentCourses) {
          this.handleGetTeacherCourseResponse(
            responseJson,
            errorResponse
          );
        }

        if (apiRequestCallId === this.apiGetCourseDropdownData) {
          this.handleGetCourseDropdownDataResponse(
            responseJson,
            errorResponse
          );
        }
      }
    }
  }
  async componentDidMount() {
    await super.componentDidMount();
    if(this.props.currentRole === "parent") {
      this.handleGetParentCourseAPI({}, {});
    }else {
      this.handleGetCourseDropdownDataApi();
      this.handleGetTeacherCoursesApi({}, {});
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.classId != this.props.classId) {
      if(this.props.currentRole === "parent") {
        this.handleGetParentCourseAPI({}, {});
      }else {
        this.handleGetCourseDropdownDataApi();
        this.handleGetTeacherCoursesApi({}, {});
      }
    }
  }

  handleChangeDropdownValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let selectValue: any = value;
    if (selectValue) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: selectValue,
        };
      });
    }
  };

  renderCoursesStatusDropdownValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Status";
    }
    const { coursesStatusComponentValue } = this.state;
    const selectedGradingComponents = coursesStatusComponentValue.find(
      (item: any) => item?.value === selected
    );
    return selectedGradingComponents?.label;
  };

  renderCoursesTitleDropdownValue = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Title";
    }
    const { coursesTitleComponentValues } = this.state;
    const selectedGradingComponents = coursesTitleComponentValues.find(
      (item: any) => item?.value === selected
    );
    return selectedGradingComponents?.label;
  };

  handleSearchCourse = () => {
    this.setState({ loading: true }, () => {
      const { selectedTitleValue, selectedStatusValue } = this.state;
      const search = {
        title: selectedTitleValue,
        status: selectedStatusValue,
      }
      if(this.props.currentRole === "parent") {
        this.handleGetParentCourseAPI(search, {});
      } else {
        this.handleGetCourseDropdownDataApi();
        this.handleGetTeacherCoursesApi(search, {});
      }
    });
  };

  handleRedirectToJourney = (data: any) => {
    this.props.openCourseJourney(data);
  }

  handleGetParentCourseAPI = async (searchQuery: any, pagination: any) => {
    showCustomLoader()
    const {  classId,currentRole,classesList } = this.props;
    let paginationLimit = 10;
    if (searchQuery.status) {
      paginationLimit = 100;
    }
    const baseUrl = configJSON.getParentCousesEndPoint + `?per_page=${paginationLimit}`;
    let urlEndPoint = baseUrl;

    if (currentRole !== "parent" && classId && classId === 'all') {
      const classIds = classesList.filter((classItem: { id: string, value: string, label: string }) => classItem.value !== "all").map((item: { id: string, value: string, label: string }) => {
        return item.value
      });
      urlEndPoint = urlEndPoint + `&class_id=[${classIds}]`;
    }
    if (currentRole !== "parent" && classId != 'all') {
      urlEndPoint = urlEndPoint + `&class_id=[${classId}]`;
    }
    if (searchQuery.title) {
      const encodedTitle = encodeURIComponent(searchQuery.title);
      urlEndPoint = urlEndPoint + `&course_title=${encodedTitle}`;
    }
    if (pagination.page) {
      urlEndPoint = urlEndPoint + `&page=${pagination.page}`;
    }

    if(currentRole === "parent" && classId != "all" ){
      urlEndPoint = urlEndPoint + `&student_id=${classId}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetParentStudentCourses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeacherCoursesApi = async (searchQuery: any, pagination: any) => {
    showCustomLoader();
    const {  classId,currentRole,classesList } = this.props;
    let paginationLimit = 10;
    if (searchQuery.status) {
      paginationLimit = 100;
    }
    const baseUrl = configJSON.getTeacherCoursesEndpoint + `?per_page=${paginationLimit}`;
    let requestUrl = baseUrl;

    if (searchQuery.title) {
      const encodedTitle = encodeURIComponent(searchQuery.title);
      requestUrl = requestUrl + `&course_title=${encodedTitle}`;
    }
    if (currentRole !== "parent teacher" && classId && classId === 'all') {
      const classIds = classesList.filter((classItem: { id: string, value: string, label: string }) => classItem.value !== "all").map((item: { id: string, value: string, label: string }) => {
        return item.value
      });
      requestUrl = requestUrl + `&class_id=[${classIds}]`;
    }

    if (currentRole !== "parent teacher" && classId != 'all') {
      requestUrl = requestUrl + `&class_id=[${classId}]`;
    }
    if(currentRole === "parent teacher" && classId != "all" ){
      requestUrl = requestUrl + `&student_id=${classId}`;
    }
    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTeacherCourses = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTeacherCourseResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      let courseList = responseJson?.data?.map(
        (item: any, index: number) => {
          return this.handleSetCourseDataItem(item);
        }
      );

      const { selectedStatusValue } = this.state;
      let paginationData: any = {};
      paginationData = responseJson.meta?.pagination;
      if (selectedStatusValue && paginationData && paginationData.total_count) {
        courseList = courseList.filter((item: any) => item.Status === selectedStatusValue);
        paginationData.total_count = courseList.length;
      }

      this.setState({
        coursesTableData: courseList || [],
        pagination: paginationData,
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleSetCourseDataItem = (item: any) => {
    const course = item.attributes;
    const {currentRole} = this.props
    return {
      id: item?.id,
      Title: {
        name: course?.course_title,
        icon: course?.subject ? course?.subject?.icon : '',
        bgColor: course?.subject ? course?.subject?.color : '#000',
        boxWidth: "32px",
        boxHeight: "32px",
      },
      StartDate: moment(course?.school_year?.start_date).format('MMMM DD, YYYY'),
      DueDate: moment(course?.school_year?.end_date).format('MMMM DD, YYYY'),
      TopicCount: course?.topic_count,
      LessonCount: course?.total_no_of_lessons,
      ActivityCount: course?.activity_count,
      Status:(currentRole === "parent teacher" || currentRole === "parent" ) 
      ? course.student_course_status || 'NA' 
      : course.teacher_course_status || 'NA',
      attributes:item && item.attributes    
    }
  };

  handlePaginationChange = (event: any, page: number) => {
    if(this.props.currentRole === "parent") {
      this.handleGetParentCourseAPI({}, {page: page});
    } else {
      this.handleGetCourseDropdownDataApi();
      this.handleGetTeacherCoursesApi({}, { page: page });
    }
  }

  handleGetCourseDropdownDataApi = async () => {
    const { classesList, classId,currentRole } = this.props;
    let requestUrl = configJSON.getTeacherCoursesEndpoint + `?per_page=${200}&page=1`;

    if (currentRole !== "parent teacher" && classId && classId === 'all') {
      const classIds = classesList.filter((classItem: { id: string, value: string, label: string }) => classItem.value !== "all").map((item: { id: string, value: string, label: string }) => {
        return item.value
      });
      requestUrl = requestUrl + `&class_id=[${classIds}]`;
    }

    if (currentRole !== "parent teacher" && classId && classId != 'all') {
      requestUrl = requestUrl + `&class_id=[${classId}]`;
    }
    if(currentRole === "parent teacher" && classId != "all"){
      requestUrl = requestUrl + `&student_id=${classId}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCourseDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetCourseDropdownDataResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const titleDropDownData = responseJson.data.map((item: any) => {
        return {
          id: item?.id,
          label: item?.attributes?.course_title,
          value: item?.attributes?.course_title,
        }
      });

      this.setState({
        coursesTitleComponentValues: titleDropDownData,
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };
  // Customizable Area End
}
