// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  IconButton,
} from "@material-ui/core";
import ChangeDueDateJourneyController, {
  Props,
} from "./ChangeDueDateJourneyController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";
import CalendarIcon from "../../cfcurriculmschoolyearcreation2/src/assets/CalendarIcon.web";
import ChangeDueDateSuccessModal from "./ChangeDueDateSuccessModal.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dueDateLessonModal: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
      "& .MuiDialog-paperWidthSm": {
        width: "880px",
        maxWidth: "1000px",
        minHeight: "500px",
        height: "max-content",
        overflowY: "hidden",
        overflowX: "hidden",
      },
    },
  });
const ModifiedDialog = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    width: "880px",
    maxWidth: "880px"
  }
})
// Customizable Area End

// Customizable Area Start
export class ChangeDueDateActivityModal extends ChangeDueDateJourneyController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
      <>
        {this.props.openDueDateSuccessModal ? (
          <>
            <ChangeDueDateSuccessModal
              dueDate={this.state.DuedateChangeLesson}
              onConfirm={this.handleCloseChangeDueDateModal}
              onCancel={this.handleCloseChangeDueDateModal}
              open={this.props.openDueDateSuccessModal}
              selectedTopic={this.props.selectedTopic}
              courseData={this.props.courseData}
              selectType={this.props.selectType}
              details={this.props.details}
            />
          </>
        ) : (
          <>
            <ModifiedDialog
              className={`dueDateLessonModal ${classes.highlightBgColor} ${classes.dueDateLessonModal}`}
              aria-labelledby="due-lesson-student-confirm-dialog-title"
              open={open}
            >
              <Box
                padding={"30px"}
                className={`${classes.bgColor}`}
                data-test-id="dueDateLessonModals"
                style={{overflowY:'auto'}}
              >
                <Grid direction="row" container>
                  <Grid xs={12} item>
                    <Typography
                      className={`${classes.fontText24} ${classes.textPrimaryColor}`}
                      component={"span"}
                      data-test-id="dueDateLessonModal_one"
                    >
                      Change Due Date
                    </Typography>
                    <IconButton
                      data-test-id="dueDateLessonModal_two"
                      style={{ float: "right" }}
                      aria-controls="long-menu"
                      aria-label="more"
                      aria-haspopup="true"
                      onClick={this.props.onCancel}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <DialogContent
                  style={{
                    padding: "0px",
                    marginTop: "24px",
                    overflow: "hidden",
                  }}
                  data-test-id="dueDateLessonModal_there"
                >
                  <Grid container spacing={2} direction="row">
                    <Grid xs={6} item>
                      <Box
                        marginBottom={"10px"}
                        width={"100%"}
                        data-test-id="dueDateLessonModal_Four"
                      >
                        <Typography
                          component={"span"}
                          className={`${classes.fontText16} ${classes.textCommonColor}`}
                          data-test-id="dueDateLessonModal_Five"
                        >
                          Course Title
                        </Typography>
                        <CustomTextField
                          placeHolderText="Physics"
                          fieldName="entertopiccount"
                          fieldId="lesson_id"
                          fieldValue={this.props.courseData?.courseName}
                          textFieldStyles={dueDateChangeLessonFieldStyle}
                          fieldWidth={"100%"}
                          fieldType="text"
                          isDisabled
                        />
                      </Box>
                    </Grid>
                    <Grid xs={6} item data-test-id="dueDateLessonModal_SIx">
                      <Box marginBottom={"10px"} width={"100%"}>
                        <Typography
                          component={"span"}
                          className={`${classes.fontText16} ${classes.textCommonColor}`}
                          data-test-id="dueDateLessonModal_seven"
                        >
                          Subject
                        </Typography>
                        <CustomTextField
                          placeHolderText="Science"
                          fieldName="entertopiccount"
                          fieldId="topic__eight"
                          textFieldStyles={textfieldSubjectStyles}
                          fieldWidth={"99%"}
                          fieldValue={this.props.courseData?.attributes?.subject?.name}
                          fieldType="text"
                          isDisabled
                        />
                      </Box>
                    </Grid>
                    <Grid xs={6} item data-test-id="dueDateLessonModal_ten">
                      <Box marginBottom={"10px"} width={"100%"}>
                        <Typography
                          component={"span"}
                          className={`${classes.fontText16} ${classes.textCommonColor}`}
                          data-test-id="dueDateLessonModalsd"
                        >
                          Topic
                        </Typography>
                        <CustomTextField
                          placeHolderText="Wave Energy"
                          fieldName="entertopiccount"
                          fieldId="dueDateLessonModal_ones"
                          textFieldStyles={dueDateChangeLessonFieldStyle}
                          fieldWidth={"100%"}
                          fieldValue={this.props.selectedTopic?.topicTitle}
                          fieldType="text"
                          isDisabled
                        />
                      </Box>
                    </Grid>
                    <Grid xs={6} item data-test-id="dueDateLessonModal_text">
                      <Box marginBottom={"10px"} width={"100%"}>
                        <Typography
                          component={"span"}
                          className={`${classes.fontText16} ${classes.textCommonColor}`}
                          data-test-id="dueDateLessonModal_Leson"
                        >
                          Lesson
                        </Typography>
                        <CustomTextField
                          placeHolderText="Wave Energy Part 1"
                          fieldName="entertopiccount"
                          fieldId="topic__Lesont"
                          textFieldStyles={dueDateChangeLessonFieldStyle}
                          fieldWidth={"100%"}
                          fieldValue={this.props.selectedLesson?.lessonTitle}
                          fieldType="text"
                          isDisabled
                        />
                      </Box>
                    </Grid>
                    <Grid xs={6} item data-test-id="dueDateLessonModal_ten">
                      <Box marginBottom={"10px"} width={"100%"}>
                        <Typography
                          component={"span"}
                          className={`${classes.fontText16} ${classes.textCommonColor}`}
                          data-test-id="dueDateLessonModalsd"
                        >
                          Activity
                        </Typography>
                        <CustomTextField
                          placeHolderText="Wave Energy"
                          fieldName="entertopiccount"
                          fieldId="dueDateLessonModal_ones"
                          textFieldStyles={dueDateChangeLessonFieldStyle}
                          fieldValue={this.props.details?.title}
                          fieldWidth={"100%"}
                          fieldType="text"
                          isDisabled
                        />
                      </Box>
                    </Grid>
                    <Grid xs={6} item data-test-id="dueDateLessonModal_Due">
                      <Box marginTop={"4px"} width={"100%"}>
                        <Typography
                          component={"span"}
                          className={`${classes.fontText16} ${classes.textCommonColor}`}
                          data-test-id="dueDateLessonModal_Date"
                          style={{ marginTop: "5px" }}
                        >
                          Due Date
                        </Typography>
                        <div
                          style={{ marginTop: "6px" }}
                          className="DateContainers"
                        >
                          <span className="dateInput">
                            {" "}
                            <DatePicker
                              data-test-id="DatePicker"
                              minDate={new Date()}
                              onChange={this.dueDateChangeLesson}
                              popperPlacement="left-start"
                              popperClassName="example-datepicker-class"
                              selected={this.state.DuedateChangeLesson}
                              dateFormat="MMMM d, yyyy"
                            />
                          </span>
                          <span className="icon">
                            <CalendarIcon />
                          </span>
                        </div>
                      </Box>
                    </Grid>
                    <Grid xs={12} item style={{marginBottom:'15px'}}>
                      <Box mb={"10px"}  style={{ width: "100%" }}>
                        <CustomButton
                          btnText="Save"
                          btnStyle={dueeDateStyleLesson}
                          buttonId={`lesson-assessment-search-mobile`}
                          buttonVariant={"contained"}
                          type={"button"}
                          handleButtonClick={this.handleDueDateSuccessModal}
                          isDisabled={false}
                        />
                        <CustomButton
                          btnText="Cancel"
                          btnStyle={lessonDateChnagedStyle}
                          buttonId={`my-Bot-search-mobile`}
                          buttonVariant={"outlined"}
                          type={"button"}
                          handleButtonClick={this.props.onCancel}
                          isDisabled={false}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Box>
            </ModifiedDialog>
          </>
        )}
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start

const textfieldSubjectStyles = {
  width: "99%",
  padding: "8px 0px",
  marginTop: "10px",
} as const;

const dueDateChangeLessonFieldStyle = {
  width: "99%",
  padding: "8px 0px",
  marginTop: "10px",
} as const;

const lessonDateChnagedStyle = {
  padding: "10px 10px",
  width: "120px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  float: "right",
  marginLeft: "15px",
} as const;

const dueeDateStyleLesson = {
  padding: "10px 10px",
  width: "120px",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  textAlign: "center",
  marginTop: "32px",
  fontWeight: 500,
  float: "right",
  marginLeft: "15px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ChangeDueDateActivityModal);
// Customizable Area End
