// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { RefObject, createRef } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { displayApiErrorMsg, getMessageData } from "../../../components/src/CommonHelper.web";
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

interface IProps {
  title: string;
  id: any;
  completionProgress: any;
  dueDate:any
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  id?: string;
  handleActivitySelectItem: any;
  classes?: any;
  key: any;
  isFromAccordions: boolean;
  detail: IProps;
  selectedItemId?: any;
  navigation?: any;
  typesOfActivity: string;
  gradeComponent?: any;
  index?: any;
  isOpenActivity?: boolean;
  isFromTenant?: boolean;
  isStudent?: boolean;
  menuType?: any;
  getMenuItem: any;
  selectedTopic?: any;
  selectedLesson?: any;
  selectedActivity?: any;
  courseData?:any;
  callActivityApi?:()=>void;
  classId?:string
  role?:string
  isActivityWrite?:boolean
  isJourneyCourseManagment?:boolean
  isParent?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  menuAnchorEl: any
  dueDateModalOpen: boolean;
  markCompleteModalOpen: boolean;
  dueDate: string;
  id: string;
  markAsCompleteSuccessModal: boolean;
  role:string
  openDueDateSuccessModal:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class StudentCourseActivityJourneyBoxController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCourseActivityDetails: string = "";
  dueDateUpdateApiCallID: string = "";
  markAsCompleteApiCallID: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.activityTitleRef = createRef();
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      loading: false,
      menuAnchorEl: null,
      dueDateModalOpen: false,
      markCompleteModalOpen: false,
      dueDate: "",
      id: "",
      markAsCompleteSuccessModal: false,
      role:'',
      openDueDateSuccessModal:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  activityTitleRef: RefObject<HTMLDivElement>;

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    const role = await getStorageData('role')
    this.handleActivityTitleWidth();
    window.addEventListener('resize', this.handleResize);
    this.setState({role:role})
    // Customizable Area End
  }

  handleResize = () => {
    if (this.activityTitleRef.current) {
      this.activityTitleRef.current.style.width = '';
    }
    this.handleActivityTitleWidth();
  }

  handleActivityTitleWidth = () => {
    if (this.activityTitleRef.current) {
      const activityTitle = this.activityTitleRef.current.innerHTML;
      this.activityTitleRef.current.innerHTML = '';
      const availableWidth = this.activityTitleRef.current.clientWidth;
      this.activityTitleRef.current.innerHTML = activityTitle;
      this.activityTitleRef.current.style.width = availableWidth + 'px';
    }
  };

  // Customizable Area Start
  handleCondition = (condition:any, truePart:any, falsePart:any) => {
    return condition ? truePart : falsePart
  }

  handleCancelClick = () => {
    this.setState({dueDateModalOpen: false});
  }
  handleMarkClose = () => {
    this.setState({markCompleteModalOpen: false, markAsCompleteSuccessModal: false});
    this.props.callActivityApi && this.props.callActivityApi()
  }

  handleConfirmClick = () => {
    this.handleDueDateChange();
  }

  setDueDate = (e: any) => {
    const formattedDate = moment(e).format("D-M-YYYY"); 
    this.setState({dueDate: formattedDate});
  }

  handleActionClick = (e: any) => {
    this.setState({ menuAnchorEl: e?.currentTarget })
  }

  handleDueDateChange = async () => {
    const token = await getStorageData("token");
    const {role} = this.state
    let requestUrl = ""
    let dueDateParams = moment(new Date()).format("D-M-YYYY");
    if(this.state.dueDate){
      dueDateParams = this.state.dueDate
    }
    if (role === 'Teacher') {
      requestUrl=  `${configJSON.dueDateEndPoint}?id=${this.state.id}&due_date=${dueDateParams}&class_id=${this.props.classId}`
    }else if(role === 'Parent Teacher'){
    requestUrl =  `${configJSON.dueDateEndPoint}?id=${this.state.id}&due_date=${dueDateParams}&student_id=${this.props.classId}`
    }else{
    requestUrl =  `${configJSON.dueDateEndPoint}?id=${this.state.id}&due_date=${dueDateParams}`
    }
    const header = {
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dueDateUpdateApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleMarkAsComplete = async () => {
    const token = await getStorageData("token");
    const{ role} =this.state
    const header = {
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsCompleteApiCallID = requestMessage.messageId;

     if (role === 'Teacher') {
      requestMessage.addData( 
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.markAsCompleteEndPoint}?id=${this.state.id}&class_id=${this.props.classId}`
      );
     }else if(role === 'Parent Teacher'){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.markAsCompleteEndPoint}?id=${this.state.id}&student_id=${this.props.classId}`
      );
     }
     else{
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.markAsCompleteEndPoint}?id=${this.state.id}`)
     }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleOptionClick = (type: string, id: string) => {
    if(type === "Change Due Date") {
      this.setState({dueDateModalOpen: true});
    }else if (type === "Mark As Complete") {
      this.setState({markCompleteModalOpen: true});
    }
    this.setState({id});
  }

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null })
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start
      const { apiRequestCallId, responseJson } =
        getMessageData(message);
      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.markAsCompleteApiCallID) {
          this.handleGetMarkAsCompleteResponse(responseJson);
        }
        if(apiRequestCallId === this.dueDateUpdateApiCallID) {
          this.handleGetDueDataChangeResponse(responseJson);
        }
      }
      // Customizable Area Start

      // Customizable Area End
    }
  }

  async componentWillUnmount() {
    await super.componentWillUnmount();
    window.removeEventListener('resize', this.handleResize);
  }

  handleGetMarkAsCompleteResponse = (response: any) => {
    if(response.message === "Only student will done this.") {
      toast.error(response.message)
      this.setState({markAsCompleteSuccessModal: false})
    }else{
      toast.success(response.message)
      this.setState({markAsCompleteSuccessModal: true})
    }
  }
  handleOpenSuccessDueDateChange=()=>{
    this.setState({ openDueDateSuccessModal: true });
  }
  handleCloseSuccessDueDateChange=()=>{
    this.setState({ openDueDateSuccessModal: false });
  }
  handleGetDueDataChangeResponse = (response: any) => {
    if(response.message){
      toast.success(response.message)
      this.setState({openDueDateSuccessModal:true})
    } else {
      displayApiErrorMsg(response?.error[0], this.props.navigation);
    }
  }

  handleDeleteMenuOption = () => {
    const menuData = {
      itemId: this.props.detail.id,
      menu: "Delete",
      itemType: this.props.menuType,
      itemName: this.props.detail.title,
    }
    if (menuData) {
      this.props.getMenuItem(menuData);
    }
    this.handleMenuClose();
  }

  handleEditMenuOption = () => {
    const menuData = {
      itemId: this.props.detail.id,
      menu: "Edit",
      itemType: this.props.menuType,
      itemName: this.props.detail.title,
    }
    if (menuData) {
      this.props.getMenuItem(menuData);
    }
    this.handleMenuClose();
  }
  // Customizable Area End
}
// Customizable Area End