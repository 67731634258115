// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { IInputConfigProps } from "../../../components/src/CommonType.web";
import { displayApiErrorMsg, getFieldType, getMessageData, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  renderDDCatalogMenuItem: any;  
  handleTextChange: any;
  handleSelectChange: any;
  inputConfigs: Array<IInputConfigProps>;
  customFieldConfig:any
  handleClassCustomFieldTextChange:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  customFieldsList: Array<any>;
  inputConfigss: Array<IInputConfigProps>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TenantCourseMngCreateCatalogStep1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCatalogCustomField: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      inputConfigss:[],
      customFieldsList:[],
      loading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  }

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.catalogCreateStep1();
    // Customizable Area End
  }



  // Customizable Area Start
  catalogCreateStep1 = () => {
    console.log("Create Catalog Step 1");
  };
  // Customizable Area End
}
